import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import { Controller } from "react-hook-form";

const FloatingInputField = ({
  name,
  rules,
  control,
  label,
  placeHolder,
  required,
  type,
  isDisabled,
  keyPressed,
  isChange,
  errors,
  errorMessage,
  onInput,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <FloatingLabel
              controlId="floatingInput"
              label={label}
              className={`mb-3 ${required && "required"}`}
            >
              <Form.Control
                {...field}
                className={`shadow-none ${errors[name] && "error-input"}`}
                disabled={isDisabled && isDisabled}
                onInput={
                  onInput &&
                  ((e) => {
                    e.target.value = onInput(e);
                  })
                }
                onKeyPress={keyPressed && keyPressed}
                placeholder={placeHolder}
                type={type}
              />
              <p className="validation-error-alert">
                {errors[name] && (
                  <Form.Text className="text-danger">{errorMessage}</Form.Text>
                )}
              </p>
            </FloatingLabel>
          </>
        )}
      />
    </>
  );
};

export default FloatingInputField;
