import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import FloatLabelSelectDropDown from "../../components/FloatingSelectInput";
import FloatingInputField from "../../components/FloatingInputField";
import { useForm } from "react-hook-form";
import { IMAGE_FORMATS, customSelectStyles } from "../../constants";
import Sidebar from "../../layouts/dashboard/BuyerLayout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getCategoriesListReq,
  advertiseBuyerRequest,
} from "../../services/api";
import { Form, Spinner } from "react-bootstrap";
import ErrorAlert from "../../components/ErrorAlert";
import { IoIosAddCircle } from "react-icons/io";
import { isEmpty } from "lodash";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const AddService = () => {
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState(null);
  const [descriptionError, setDescriptionError] = useState(false);
  const [autocomplete, setAutoComplete] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [image, setImages] = useState(null);

  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const addBuyerRequest = async (body) => {
    setIsLoading(true);
    try {
      await advertiseBuyerRequest(id, body);
      navigate("/buyer/all-jobs-requests");
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleAdvertiseJob = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("price", values.price);
    formData.append("catId", values.category.value);
    formData.append("description", description);
    formData.append("lat", lat || 0);
    formData.append("lng", lng || 0);
    formData.append("timeline", values.timeLine);
    formData.append("image", image);
    addBuyerRequest(formData);
  };

  const handleSelectCategory = (values) => {
    setValue("category", {
      label: values.label,
      value: values.value,
    });
    clearErrors("category");
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.message || err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleCKEditor = (data) => {
    if (isEmpty(data)) {
      setDescription(null);
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
      setDescription(data);
    }
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEAOhFgtiWHQMMBP1XUkxGjjWLpjqsnLQ",
    libraries: ["places"],
  });
  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setValue("location", autocomplete.getPlace().formatted_address);
      setLat(autocomplete.getPlace().geometry.location.lat());
      setLng(autocomplete.getPlace().geometry.location.lng());
    } else {
      setErrorMessage("Locations is not loaded yet!");
    }
  };
  const handleImageFileValidation = async (fileList) => {
    if (fileList) {
      if (!IMAGE_FORMATS.includes(fileList[0]?.type)) {
        setImageError("Image should be jpeg, png, jpg or pdf format");
        return;
      }
      setImages(fileList[0]);
      setImageError(null);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <Layout>
        <>
          <ErrorAlert
            message={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <div className="content-dashboard">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <h2 className="mb-3">Post A Request</h2>
                  <div className="container add-service-form-container">
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <FloatingInputField
                          name={"title"}
                          type={"text"}
                          placeHolder={"Enter Title"}
                          label={"Project Name"}
                          control={control}
                          rules={{ required: true }}
                          errors={errors}
                          errorMessage={"Title is required"}
                        />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        {isLoaded && (
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            restrictions={{
                              country: ["ci"],
                            }}
                          >
                            <FloatingInputField
                              name={"location"}
                              type={"text"}
                              placeHolder={"Search posatal code"}
                              label={"Location where you provide the service"}
                              control={control}
                              rules={{ required: true }}
                              errors={errors}
                              errorMessage={"Location is required"}
                            />
                          </Autocomplete>
                        )}
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <FloatingInputField
                          name={"price"}
                          type={"number"}
                          placeHolder={"Enter Price"}
                          label={"Budget"}
                          control={control}
                          rules={{ required: true }}
                          errors={errors}
                          errorMessage={"Price is required"}
                        />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <FloatLabelSelectDropDown
                          name={"category"}
                          placeHolder={""}
                          control={control}
                          label={"Category"}
                          rules={{ required: true }}
                          options={categories}
                          customStyle={customSelectStyles}
                          errorMessage={"Category is required"}
                          errors={errors}
                          onChange={handleSelectCategory}
                        />{" "}
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <FloatingInputField
                          name={"timeLine"}
                          type={"date"}
                          placeHolder={"Enter Completion date"}
                          label={"Estimated Completion date"}
                          control={control}
                          rules={{ required: true }}
                          errors={errors}
                          errorMessage={"Estimated completion date is required"}
                        />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <Form.Group
                          className="mb-3 mt-2"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            <Form.Control
                              type="file"
                              multiple
                              placeholder="Upload Images"
                              onChange={(event) =>
                                handleImageFileValidation(event.target.files)
                              }
                            />
                            Upload Attachment if any
                          </Form.Label>
                          <Form.Text className="text-muted">
                            {imageError && imageError}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <CKEditor
                          name={"description"}
                          control={control}
                          rules={{ required: true }}
                          config={{
                            toolbar: [
                              "heading",
                              "bold",
                              "italic",
                              "undo",
                              "redo",
                              "numberedList",
                              "bulletedList",
                            ],
                          }}
                          editor={ClassicEditor}
                          data=""
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onHandleCKEditor(data);
                          }}
                        />
                        <p className="validation-error-alert">
                          {descriptionError && (
                            <Form.Text className="text-danger">
                              Description is Required
                            </Form.Text>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        className="w-25 login-btn"
                        onClick={handleSubmit(onHandleAdvertiseJob)}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            Post <IoIosAddCircle className="mb-1" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
};

export default AddService;
