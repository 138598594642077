import axios from "axios";
import successHandler from "./successHandler";
import errorHandler from "./errorHandler";
import { store } from "../../store/store";

export const NetworkConfig = () => {
  axios.defaults.baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL
      : "http://127.0.0.1:8080/api";
  axios.defaults.timeout = 10000;
  axios.defaults.headers.get["Accept"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";
  const delay = (duration) => {
    return new Promise((resolve) => setTimeout(resolve, duration));
  };
  axios.interceptors.request.use(async (config) => {
    const delayInMs = 250;
    await delay(delayInMs);
    const accessToken = store.getState().auth.accessToken;
    if (accessToken) {
      config.headers.Authorization = accessToken;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  });
  axios.interceptors.response.use(successHandler, errorHandler);
};
