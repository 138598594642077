import React from "react";
import Select from "react-select";
import { customSelectStyles } from "../../constants";
import { BiSearchAlt2 } from "react-icons/bi";

const Filters = ({
  categories,
  countries,
  cities,
  locations,
  categoryId,
  countryId,
  cityId,
  locationId,
  setCategoryId,
  setCityId,
  setLocationId,
  setCountryId,
  onHandleSearch,
}) => {
  const getDefaultCategory = () => {
    return categories[
      categories.findIndex((cat) => cat.value === Number(categoryId))
    ];
  };
  const getDefaultCountry = () => {
    getDefaultCity(1);
    return countries[countries.findIndex((cat) => cat.value === Number(1))];
  };
  const getDefaultCity = () => {
    return cities[cities.findIndex((cat) => cat.value === Number(cityId))];
  };
  const getDefaultLocation = () => {
    return locations[
      locations.findIndex((cat) => cat.value === Number(locationId))
    ];
  };

  return (
    <div className="filters-row ">
      <div className="container filter-container">
        {/* <div className="search-field">
          <Form>
            <Form.Control type="email" placeholder="Search by Name" />
          </Form>
        </div> */}
        {getDefaultCategory() && (
          <div className="filter-dropdown">
            <Select
              defaultValue={getDefaultCategory()}
              options={categories}
              placeholder="Search by Category"
              styles={customSelectStyles}
              onChange={setCategoryId}
            />
          </div>
        )}
        {!getDefaultCategory() && (
          <div className="filter-dropdown">
            <Select
              options={categories}
              placeholder="Search by Category"
              styles={customSelectStyles}
              onChange={setCategoryId}
            />
          </div>
        )}
        {/* {getDefaultCountry() && (
          <div className="filter-dropdown">
            <Select
              defaultValue={getDefaultCountry()}
              options={countries}
              placeholder="Search by Country"
              styles={customSelectStyles}
              onChange={setCountryId}
            />
          </div>
        )} */}
        {/* {!getDefaultCountry() && (
          <div className="filter-dropdown">
            <Select
              options={countries}
              placeholder="Search by Country"
              styles={customSelectStyles}
              onChange={setCountryId}
            />
          </div>
        )} */}
        {getDefaultCity() && (
          <div className=" filter-dropdown">
            <Select
              defaultValue={getDefaultCity()}
              options={cities}
              placeholder="Search by City"
              styles={customSelectStyles}
              onChange={setCityId}
            />
          </div>
        )}
        {!getDefaultCity() && (
          <div className=" filter-dropdown">
            <Select
              options={cities}
              placeholder="Search by City"
              styles={customSelectStyles}
              onChange={setCityId}
            />
          </div>
        )}
        {/* {getDefaultLocation() && (
          <div className=" filter-dropdown">
            <Select
              defaultValue={getDefaultLocation()}
              options={locations}
              placeholder="Search by Location"
              styles={customSelectStyles}
              onChange={setLocationId}
            />
          </div>
        )} */}
        {locations.length > 0 && !getDefaultLocation() && (
          <div className=" filter-dropdown">
            <Select
              options={locations}
              placeholder="Search by Location"
              styles={customSelectStyles}
              onChange={setLocationId}
            />
          </div>
        )}
        <div className="search-btn mt-2">
          <button onClick={onHandleSearch}>
            Search
            <BiSearchAlt2 />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Filters;
