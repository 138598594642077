import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import FloatLabelSelectDropDown from "../../components/FloatingSelectInput";
import FloatingInputField from "../../components/FloatingInputField";
import { useForm } from "react-hook-form";
import { customSelectStyles, IMAGE_FORMATS } from "../../constants";
import Sidebar from "../../layouts/dashboard/Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getUserSkillsReq,
  getCategoriesReq,
  addServicesReq,
} from "../../services/api";
import { Form, Spinner } from "react-bootstrap";
import ErrorAlert from "../../components/ErrorAlert";
import { ImCross } from "react-icons/im";
import { IoIosAddCircle } from "react-icons/io";
import { isEmpty } from "lodash";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Autocomplete,
} from "@react-google-maps/api";

const AddService = () => {
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [skillsArray, setSkillsArray] = useState([]);
  const [description, setDescription] = useState(null);
  const [descriptionError, setDescriptionError] = useState(false);
  const [autocomplete, setAutoComplete] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useForm({ defaultValues: { title: "", price: 0 } });

  const addService = async (body) => {
    setIsLoading(true);
    try {
      await addServicesReq(id, body);
      navigate(`/seller/services`);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleAddService = (values) => {
    if (!images.length > 0 || imageError) {
      return setImageError("Images Required");
    } else {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("categoryId", values.category.value);
      for (const image of images) {
        formData.append("images", image);
      }
      formData.append("description", description);
      formData.append("lat", lat);
      formData.append("lng", lng);

      addService(formData);
    }
  };

  const handleSelectCategory = (values) => {
    setValue("category", {
      label: values.label,
      value: values.value,
    });
    clearErrors("category");
  };

  const handleSelectStatus = (values) => {
    setValue("status", {
      label: values.label,
      value: values.label,
    });
    clearErrors("status");
  };

  const handleSelectSkills = (values) => {
    if (values.length < 1) {
      setError("skills");
    } else {
      clearErrors("skills");
    }
    setValue("skills", values);
    setSkillsArray(
      values.map((value) => {
        return value.value;
      })
    );
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesReq(id);
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onRemoveImage = (key) => {
    setImagePreview((currentState) => {
      return currentState.filter((image, index) => index != key);
    });
    setImages((currentState) => {
      return Array.from(currentState).filter((file, index) => index != key);
    });
  };

  const handleImageFileValidation = async (fileList) => {
    let imageURLs = [];
    if (fileList) {
      if (fileList.length >= 1 && fileList.length <= 3) {
        for (let i = 0; i < fileList.length; i++) {
          if (!IMAGE_FORMATS.includes(fileList[i]?.type)) {
            setImageError("Image should be jpeg, png or jpg");
            return;
          } else {
            imageURLs = [...imageURLs, URL.createObjectURL(fileList[i])];
          }
          setImagePreview(imageURLs);
          setImages(fileList);
          setImageError(null);
        }
      } else {
        setImageError("Min 1 and Max 3 Images required");
        return;
      }
    } else {
      setImageError("Images required");
      return;
    }
  };

  const onHandleCKEditor = (data) => {
    setDescription(data);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEAOhFgtiWHQMMBP1XUkxGjjWLpjqsnLQ",
    libraries: ["places"],
  });
  const mapContainerStyle = {
    height: "73vh",
    width: "45vw",
  };
  const center = {
    lat: 38.685,
    lng: -115.234,
  };
  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setValue("location", autocomplete.getPlace().formatted_address);
      setLat(autocomplete.getPlace().geometry.location.lat());
      setLng(autocomplete.getPlace().geometry.location.lng());
    } else {
      setErrorMessage("Locations is not loaded yet!");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <Layout>
        <>
          <ErrorAlert
            message={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <div className="content-dashboard">
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-md-8">
                  <h2 className="mb-3">Add Service</h2>
                  <div className="container add-service-form-container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <FloatingInputField
                          name={"title"}
                          type={"text"}
                          placeHolder={"Enter Title"}
                          label={"Title"}
                          control={control}
                          rules={{ required: true }}
                          errors={errors}
                          errorMessage={"Title is required"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <FloatLabelSelectDropDown
                          name={"category"}
                          placeHolder={""}
                          control={control}
                          label={"Services Offered"}
                          rules={{ required: true }}
                          options={categories}
                          customStyle={customSelectStyles}
                          errorMessage={"Category is required"}
                          errors={errors}
                          onChange={handleSelectCategory}
                        />{" "}
                      </div>
                      <div className="col-lg-6 col-md-12">
                        {isLoaded && (
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            restrictions={{
                              country: ["ci"],
                            }}
                          >
                            <FloatingInputField
                              name={"location"}
                              type={"text"}
                              placeHolder={"Search posatal code"}
                              label={"Location where you provide the service"}
                              control={control}
                              rules={{ required: true }}
                              errors={errors}
                              errorMessage={"Location is required"}
                            />
                          </Autocomplete>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <CKEditor
                          name={"description"}
                          control={control}
                          rules={{}}
                          config={{
                            toolbar: [
                              "heading",
                              "bold",
                              "italic",
                              "undo",
                              "redo",
                              "numberedList",
                              "bulletedList",
                            ],
                          }}
                          editor={ClassicEditor}
                          data=""
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onHandleCKEditor(data);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Form.Group
                          className="mb-3 mt-2"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Add a profile picture of yourself or of your company
                            so customers will know exactly who they’ll be
                            working with
                          </Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            placeholder="Upload Images"
                            onChange={(event) =>
                              handleImageFileValidation(event.target.files)
                            }
                          />
                          <Form.Text className="text-muted">
                            {imageError && imageError}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      {imagePreview &&
                        imagePreview.map((image, i) => {
                          return (
                            <div key={i} className="col-4">
                              <div className="image-container">
                                <img src={image} />
                                <div className="remove">
                                  <ImCross onClick={() => onRemoveImage(i)} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="w-25 login-btn"
                      onClick={handleSubmit(onHandleAddService)}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <>
                          Add Service <IoIosAddCircle className="mb-1" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
};

export default AddService;
