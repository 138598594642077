import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import nodejs from "../../assets/images/nodejs.png";
import andriod from "../../assets/images/andriod.png";
import { useSelector } from "react-redux";
import { chatRoomMessages, sendRoomMessage } from "../../services/api";
// import ChatRoom from "../../components/Chat/ChatRoom";
import { Controller, useForm } from "react-hook-form";
import welcome from "../../assets/images/welcome.png";
import { Button } from "react-bootstrap";
let userId = localStorage.getItem("persist:root")
  ? JSON.parse(localStorage.getItem("persist:root"))
  : null;
if (userId) {
  userId = userId && JSON.parse(userId?.auth)?.user.id;
}
let queryParams = { userId: userId };
const url = process.env.SOCKET_URI
const socket = io(url, {
  transports: ["websocket"],
  query: queryParams,
  reconnection: true,
  rejectUnauthorized: false,
});

const ChatList = (props) => {
  let chatLists = props?.data;
  const state = useSelector((state) => state);
  const { id, name, role } = state.auth.user;

  const [isLoading, setIsLoading] = useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const [roomUser, setRoomUser] = useState(null);
  const [getRoomId, setRoomId] = useState(null);

  const [typingStatus, setTypingStatus] = useState(null);
  const [messages, setMessages] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [offlineUsers, setOffLineUsers] = useState([]);
  const [messageRoomId, setMessageRoomId] = useState(null);
  const lastMessageRef = useRef(null);
  const [reqMessages, setReqMessages] = useState({});
  const [roomUnreadMsgs, setRoomUnreadMsgs] = useState(props?.unReadMsgs);
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { message: "" } });

  const handleSendMessage = async (body) => {
    setIsLoading(true);
    try {
      const realTimeMsg = {
        message: body.message,
        name: name,
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        messageBy: {
          id: id,
        },
        roomId: getRoomId,
      };
      socket.emit("message", {
        realTimeMsg,
        roomId: getRoomId,
      });

      await sendRoomMessage(id, getRoomId, body);
      setValue("message", "");
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getRoomMessages = async (roomId) => {
    setIsLoading(true);
    try {
      socket.emit("leaveRoom", roomId);
      setRoomId(roomId);
      socket.emit("roomId", roomId);
      const requests = await chatRoomMessages(id, roomId);
      const { roomMessages, roomUser } = requests.data.data;
      const indexOfUnread =
        roomUnreadMsgs[
        roomUnreadMsgs?.findIndex((room) => room?.fk_room_id === roomId)
        ];
      if (indexOfUnread) {
        roomUnreadMsgs[
          roomUnreadMsgs?.findIndex((room) => room?.fk_room_id === roomId)
        ].unreadCount = 0;
      }
      // setRoomUnreadMsgs(roomUnreadMsgs[roomUnreadMsgs.findIndex((room) => room.fk_room_id === roomId)])
      setRoomUser(roomUser);
      setReqMessages(roomMessages);
      setMessages(roomMessages?.messages);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message || err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleTyping = () =>
    socket.emit("typing", { roomId: getRoomId, id: id, text: `typing....` });
  const handleStopTyping = () => {
    const delayFn = setTimeout(
      () => socket.emit("stopTyping", getRoomId),
      2500
    );
    return () => clearTimeout(delayFn);
  };

  useEffect(() => {
    socket.on("messageResponse", async (data) => {
      const index = chatLists.findIndex((chat) => chat.id === data.roomId);
      if (index >= 0) {
        chatLists[index]?.messages?.unshift(data);
      }
      if (Number(reqMessages.msgRoomId) === data.roomId) {
        setMessages([...messages, data]);
      }
    });
    return () => socket.off("messageResponse");
  }, [socket, messages]);

  useEffect(() => {
    socket.on("typingResponse", (data) => {
      if (data.roomId === Number(reqMessages.msgRoomId)) {
        setTypingStatus(data);
      }
    });
    socket.on("stopTypingResponse", (data) => {
      if (data === Number(reqMessages.msgRoomId)) {
        setTypingStatus(null);
      }
    });

    socket.on("offline", (data) => {
      setOffLineUsers(data);
    });
    socket.emit("online", { userId: id });
    socket.on("loggedInUsers", (data) => {
      setOnlineUsers(data);
    });
    return () => {
      socket.off("typingResponse");
      socket.off("stopTypingResponse");
    };
  }, [socket, messages]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <div className="chat-cont-left">
        <div className="chat-header">
          <div style={ { margin: "0px", paddingRight: "10px" } }>
            <Link
              to={ role === "Buyer" ? "/buyer/dashboard" : "/seller/dashboard" }
              className="btn btn-primary back-btn"
              style={ {
                margin: "0px",
                padding: "opx",
                fontSize: "10px",
                background: "#2f7932",
              } }
            >
              <i className="fa fa-arrow-left" /> Back
            </Link>
          </div>
          <form className="chat-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <i className="fas fa-search icon-circle" />
              </div>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search"
              />
            </div>
          </form>
        </div>
        <div className="chat-users-list">
          <div className="chat-scroll">
            { chatLists &&
              chatLists.map((chat) => (
                <a
                  href="#"
                  className={ `media d-flex ${getRoomId === chat.id ? "active" : ""
                    }` }
                  onClick={ () => getRoomMessages(chat.id) }
                >
                  <div className="media-img-wrap flex-shrink-0">
                    <div className="avatar avatar-away">
                      <img
                        src={ nodejs }
                        alt="User Image"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="media-body flex-grow-1">
                    <div>
                      <div className="user-name">
                        { chat?.userBy?.id === id
                          ? chat?.userTo?.name
                          : chat?.userBy?.name }{ " " }
                      </div>
                      <div className="user-last-chat">
                        { chat?.messages[0]?.message }
                      </div>
                    </div>
                    <div>
                      <div className="last-chat-time block">
                        { new Date(chat?.messages[0]?.createdAt).toISOString }
                      </div>
                      <div
                        className="badge bgg-yellow badge-pill"
                        style={ { padding: "2px" } }
                      >
                        { roomUnreadMsgs.find(
                          (room) => room.fk_room_id === chat.id
                        )?.unreadCount > 0 &&
                          roomUnreadMsgs.find(
                            (room) => room.fk_room_id === chat.id
                          ).unreadCount }
                      </div>
                    </div>
                  </div>
                </a>
              )) }
            {/* <a href=";" className="media read-chat active d-flex">
                        <div className="media-img-wrap flex-shrink-0">
                            <div className="avatar avatar-online">
                                <img
                                    src={andriod}
                                    alt="User Image"
                                    className="avatar-img rounded-circle"
                                />
                            </div>
                        </div>
                        <div className="media-body flex-grow-1">
                            <div>
                                <div className="user-name">Mickey Bernier </div>
                                <div className="user-last-chat">
                                    Lorem Ipsum is simply dummy text
                                </div>
                            </div>
                            <div>
                                <div className="last-chat-time block">
                                    05 Min
                                </div>
                                <div className="badge bgg-yellow badge-pill">
                                    5
                                </div>
                            </div>
                        </div>
                    </a> */}
          </div>
        </div>
      </div>

      { roomUser && (
        <div className="chat-cont-right">
          <div className="chat-header">
            <a id="back_user_list" href="" className="back-user-list">
              <i className="material-icons">chevron_left</i>
            </a>
            <div className="media d-flex">
              <div className="media-img-wrap flex-shrink-0">
                <div className="avatar avatar-online">
                  <img
                    src={ welcome }
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
              </div>
              <div className="media-body flex-grow-1">
                <div className="user-name">{ roomUser?.name }</div>
                <div className="user-status">
                  { typingStatus?.id !== id && typingStatus
                    ? typingStatus?.text
                    : onlineUsers.includes(roomUser?.id)
                      ? "online"
                      : roomUser?.last_seen
                        ? `last seen ${roomUser?.last_seen}`
                        : "offline" }
                </div>
              </div>
            </div>
            <div className="chat-options">
              <a href="" data-bs-toggle="modal" data-bs-target="#voice_call">
                <i className="material-icons icon-grey">local_phone</i>
              </a>
              <a href="" data-bs-toggle="modal" data-bs-target="#video_call">
                <i className="material-icons icon-grey">videocam</i>
              </a>
              <a href="">
                <i className="material-icons">more_vert</i>
              </a>
            </div>
          </div>
          <div className="chat-body">
            <div className="chat-scroll">
              <ul className="list-unstyled">
                { messages.length > 0 &&
                  messages.map((msg, index) => (
                    <div>
                      { msg.messageBy.id !== id ? (
                        <li className="media received d-flex" key={ index }>
                          <div className="media-body flex-grow-1">
                            <div className="msg-box">
                              <div>
                                <p>{ msg.message }</p>
                                <ul className="chat-msg-info">
                                  <li>
                                    <div className="chat-time">
                                      <span>10:00 AM</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li className="media sent  d-flex" key={ index + 2 }>
                          <div className="media-body flex-grow-1">
                            <div className="msg-box">
                              <div>
                                <p>{ msg.message }</p>
                                <ul className="chat-msg-info">
                                  <li>
                                    <div className="chat-time">
                                      {/* <span>10:02 AM</span> */ }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) }
                    </div>
                  )) }
                <div ref={ lastMessageRef && lastMessageRef } />
              </ul>
            </div>
          </div>
          <div className="chat-footer">
            <div className="input-group">
              <Controller
                name={ "message" }
                control={ control }
                rules={ { required: true } }
                render={ ({ field }) => (
                  <>
                    <input
                      { ...field }
                      onKeyDown={ handleTyping }
                      onKeyUp={ handleStopTyping }
                      type={ "textarea" }
                      placeholder={ "Message" }
                      label={ "Type a message" }
                      errors={ errors }
                      errormessage={ "Please type message" }
                      className="input-msg-send form-control"
                    />
                  </>
                ) }
              />

              <Button
                onClick={ handleSubmit(handleSendMessage) }
                // onSubmit
                className="btn btn-primary msg-send-btn rounded-pill"
                type="submit"
              >
                <i className="material-icons">send</i>
              </Button>
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

export default ChatList;
