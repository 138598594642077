import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/dashboard/BuyerLayout";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Jobs from "../../components/jobs/jobs";
import "../../assets/scss/BuyerDashboard.scss";
import { Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "../../components/Carousel/ButtonGroup";
import construction from "../../assets/images/andriod.png";
import placeHolder from "../../assets/images/andriod.png";
import Service from "../../components/Service.jsx";
import Provider from "../../components/Provider.jsx";
import {
  getCategoriesListReq,
  getRecommendedPros,
  getTopRatedSerives,
} from "../../services/api";
function Dashboard(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [categories, setCategories] = useState([]);
  const [recommendedPros, setRecommendedPros] = useState([]);
  const [topRatedServices, setTopRatedServices] = useState([]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(fetchedCategories.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getRecPros = async () => {
    setIsLoading(true);
    try {
      const { data } = await getRecommendedPros();
      setRecommendedPros(data.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getTopRatedServices = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTopRatedSerives();

      setTopRatedServices(data.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getRecPros();
    getTopRatedServices();
    getCategories();
  }, []);
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-md-10">
                  <div
                    style={{
                      "overflow-x": "unset",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="buyer-request">
                          <span className="welcome-section-title text-display-6">
                            Hi
                          </span>
                          <p>Get matched with sellers for your project.</p>
                          <Link to="/buyer/advertiseJob">
                            {" "}
                            <Button variant="success">Post A Request</Button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Carousel
                          additionalTransfrom={0}
                          arrows={true}
                          autoPlaySpeed={3000}
                          autoPlay
                          centerMode={false}
                          className="buyer-banner"
                          containerClass="container-padding-bottom"
                          customButtonGroup=""
                          dotListClass=""
                          draggable
                          focusOnSelect={false}
                          infinite={false}
                          itemClass=""
                          keyBoardControl
                          minimumTouchDrag={80}
                          pauseOnHover
                          renderArrowsWhenDisabled={false}
                          renderButtonGroupOutside={true}
                          renderDotsOutside={false}
                          responsive={{
                            desktop: {
                              breakpoint: {
                                max: 3000,
                                min: 1024,
                              },
                              items: 4.7,
                              partialVisibilityGutter: 40,
                            },
                            mobile: {
                              breakpoint: {
                                max: 464,
                                min: 0,
                              },
                              items: 2,
                              partialVisibilityGutter: 30,
                            },
                            tablet: {
                              breakpoint: {
                                max: 1024,
                                min: 464,
                              },
                              items: 2,
                              partialVisibilityGutter: 30,
                            },
                          }}
                          rewind={true}
                          rewindWithAnimation={true}
                          rtl={false}
                          shouldResetAutoplay={true}
                          showDots={false}
                          sliderClass=""
                          slidesToSlide={1}
                          swipeable={false}
                        >
                          {categories.length > 0 &&
                            categories.map((category) => (
                              <div className="buyer-content">
                                <a
                                  href={`/search?category=${category.title}&categoryId=${category.id}`}
                                  className="service-tile box-shadow-z2"
                                >
                                  <img
                                    src={category.image}
                                    alt="Mobile Apps"
                                    loading="lazy"
                                  />
                                  <p className="text-body-2">
                                    {category.title}
                                  </p>
                                </a>
                              </div>
                            ))}
                        </Carousel>
                      </div>
                    </div>
                    <div className="row">
                      {recommendedPros.length > 0 && (
                        <section className="container custom-slider mb-10">
                          <div
                            style={{
                              position: "relative",
                              padding: "16px 32px 32px",
                            }}
                          >
                            <h4>Recommended Pros</h4>
                            <Carousel
                              additionalTransfrom={0}
                              arrows={false}
                              autoPlaySpeed={3000}
                              autoPlay
                              centerMode={false}
                              className=""
                              containerClass="container-padding-bottom"
                              customButtonGroup={<ButtonGroup />}
                              dotListClass=""
                              draggable
                              focusOnSelect={false}
                              infinite={false}
                              itemClass=""
                              keyBoardControl
                              minimumTouchDrag={80}
                              pauseOnHover
                              renderArrowsWhenDisabled={false}
                              renderButtonGroupOutside={true}
                              renderDotsOutside={false}
                              responsive={{
                                desktop: {
                                  breakpoint: {
                                    max: 3000,
                                    min: 1024,
                                  },
                                  items: 4.5,
                                  partialVisibilityGutter: 40,
                                },
                                mobile: {
                                  breakpoint: {
                                    max: 464,
                                    min: 0,
                                  },
                                  items: 1,
                                  partialVisibilityGutter: 30,
                                },
                                tablet: {
                                  breakpoint: {
                                    max: 1024,
                                    min: 464,
                                  },
                                  items: 1,
                                  partialVisibilityGutter: 30,
                                },
                              }}
                              rewind={true}
                              rewindWithAnimation={true}
                              rtl={false}
                              shouldResetAutoplay={true}
                              showDots={false}
                              sliderClass=""
                              slidesToSlide={1}
                              swipeable={false}
                            >
                              {recommendedPros.map((service) => (
                                <Service data={service} />
                              ))}
                            </Carousel>
                          </div>
                        </section>
                      )}
                      {topRatedServices.length > 0 && (
                        <section className="container custom-slider mb-10">
                          <div
                            style={{
                              position: "relative",
                              padding: "16px 32px 32px",
                            }}
                          >
                            <h4>Top Rated Services</h4>
                            <Carousel
                              additionalTransfrom={0}
                              arrows={false}
                              autoPlaySpeed={3000}
                              autoPlay
                              centerMode={false}
                              className=""
                              containerClass="container-padding-bottom"
                              customButtonGroup={<ButtonGroup />}
                              dotListClass=""
                              draggable
                              focusOnSelect={false}
                              infinite={false}
                              itemClass=""
                              keyBoardControl
                              minimumTouchDrag={80}
                              pauseOnHover
                              renderArrowsWhenDisabled={false}
                              renderButtonGroupOutside={true}
                              renderDotsOutside={false}
                              responsive={{
                                desktop: {
                                  breakpoint: {
                                    max: 3000,
                                    min: 1024,
                                  },
                                  items: 4.5,
                                  partialVisibilityGutter: 40,
                                },
                                mobile: {
                                  breakpoint: {
                                    max: 464,
                                    min: 0,
                                  },
                                  items: 1,
                                  partialVisibilityGutter: 30,
                                },
                                tablet: {
                                  breakpoint: {
                                    max: 1024,
                                    min: 464,
                                  },
                                  items: 1,
                                  partialVisibilityGutter: 30,
                                },
                              }}
                              rewind={true}
                              rewindWithAnimation={true}
                              rtl={false}
                              shouldResetAutoplay={true}
                              showDots={false}
                              sliderClass=""
                              slidesToSlide={1}
                              swipeable={false}
                            >
                              {topRatedServices.map((service) => (
                                <Service data={service} />
                              ))}
                            </Carousel>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Dashboard;
