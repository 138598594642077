import React, { useState } from "react";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Jobs from "../../components/jobs/jobs";
import BuyerRequests from "../../components/BuyerRequests/BuyerRequests";
import Proposals from "../../components/BuyerRequests/Proposals";
import { useSelector } from "react-redux";
import { getBuyerRequest, submittedProposals } from "../../services/api/seller";
import { useEffect } from "react";
function Index(props) {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [buyerRequests, setBuyerRequests] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const getBuyerRequests = async (status) => {
    setIsLoading(true);
    try {
      const requests = await getBuyerRequest(id, status);
      const { data } = requests.data;
      setBuyerRequests(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getSubmittedProposals = async () => {
    setIsLoading(true);
    try {
      const requests = await submittedProposals(id);
      const { data } = requests.data;
      setProposals(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const handleSelect = (key) => {
    if (key === "Proposal Submitted") {
      getSubmittedProposals();
    } else {
      getBuyerRequests("active");
    }
    // getBuyerRequests(key)
  };
  useEffect(() => {
    getBuyerRequests("active");
  }, []);

  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="page-title">
                    <h3>Requests</h3>
                  </div>
                  <Tabs
                    defaultActiveKey="Jobs requests"
                    className="nav nav-tabs nav-tabs-bottom nav-justified"
                    id="controlled-tabs"
                    selectedTabClassName="bg-white"
                    onSelect={handleSelect}
                  >
                    <Tab
                      className="nav-item"
                      eventKey="Jobs requests"
                      title="Active Proposal (s)"
                    >
                      <BuyerRequests data={buyerRequests} />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="Proposal Submitted"
                      title=" Proposal (s) Submitted"
                    >
                      <Proposals data={proposals} role={"Seller"} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Index;
