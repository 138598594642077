import Button from "react-bootstrap/Button";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;
  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: 0,
      }}
    >
      <Button
        variant="light"
        style={{
          borderRadius: "50%",
        }}
        className={currentSlide === 0 ? "disabled" : ""}
        onClick={() => previous()}
      >
        <i className="fa fa-arrow-left"></i>{" "}
      </Button>
      <Button
        variant="light"
        style={{
          borderRadius: "50%",
        }}
        className={currentSlide + slidesToShow === totalItems ? "disabled" : ""}
        onClick={() => next()}
      >
        <i className="fa fa-arrow-right"></i>{" "}
      </Button>
    </div>
  );
};
export default ButtonGroup;
