import React from "react";
import { Link } from "react-router-dom";
import dummy from "../../assets/images/dummy.png";

const FreelanceWidget = ({ user, setActiveMarker }) => {
  return (
    <>
      <div
        onClick={() => setActiveMarker(user.id)}
        className="freelance-widget"
      >
        <div className="freelance-content">
          {/* <a data-bs-toggle="modal" href="#rating" className="favourite">
            <i className="fas fa-star" />
          </a> */}
          <div className="freelance-img">
            <a href="#">
              <img
                src={user.profilePic ? user.profilePic : dummy}
                alt="User Image"
              />
              {user?.verified && (
                <span className="verified">
                  <i className="fas fa-check-circle" />
                </span>
              )}
            </a>
          </div>
          <div className="freelance-info">
            <h3>
              <a href="#">{user?.name}</a>
            </h3>
            {/* <div className="freelance-specific">UI/UX Designer</div> */}
            <div className="freelance-location">
              <i className="fas fa-map-marker-alt me-1" />
              {user?.city?.title}, {user?.country?.title}
            </div>
            {user?.reviews && (
              <div className="rating">
                <i className="fas fa-star filled" />
                <i className="fas fa-star filled" />
                <i className="fas fa-star filled" />
                <i className="fas fa-star filled" />
                <i className="fas fa-star" />
                <span className="average-rating">{user?.reviews}</span>
              </div>
            )}
            <div className="freelance-tags">
              {user?.userSkills?.length > 0
                ? user?.userSkills?.map((skill, key) => {
                    return (
                      <a key={key} href="">
                        <span className="badge badge-pill badge-design">
                          {skill?.skill.title}
                        </span>
                      </a>
                    );
                  })
                : "No skills added"}
            </div>
            <div className="freelancers-price">
              {user?.hourly_rate
                ? `$${user?.hourly_rate} Hourly`
                : "No hourly rate"}
            </div>
          </div>
        </div>
        <div className="cart-hover">
          <Link
            to={`/providerDetails/${user?.id}`}
            className="btn-cart"
            tabIndex={-1}
          >
            View Profile
          </Link>
        </div>
      </div>
    </>
  );
};

export default FreelanceWidget;
