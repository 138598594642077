import React, { useState, useEffect } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import logo from "../assets/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/features/authSlice";
import { Link, useNavigate } from "react-router-dom";
import i18n from "i18next";
import english from "../assets/images/english.png";
import french from "../assets/images/french.png";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { getCategoriesListReq } from "../services/api";
import CustomModal from "../components/CustomModal";
import SelectLocation from "../pages/home/SelectLocation";
import { customSelectStyles } from "../constants";

const Layout = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [header, setHeader] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const state = useSelector((state) => state);
  const { isAuthenticated, user } = state.auth;
  const { name, role } = user;

  const [countries] = useState([
    { code: "en", title: "English" },
    { code: "fr", title: "French" },
  ]);
  const languageOptions = [
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={ { marginRight: "10px" } }>English</span>
          <img alt="en" src={ english } height="25px" width="25px" />
        </div>
      ),
      value: "en",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={ { marginRight: "10px" } }>Français</span>
          <img alt="fr" src={ french } height="25px" width="25px" />
        </div>
      ),
      value: "fr",
    },
  ];

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const changeLang = (langName) => {
    localStorage.setItem("lang", langName);
    i18n.changeLanguage(langName);
    navigate(0);
  };

  const handleShowSearch = () => setShowSearch(true);
  const handleCloseSearch = () => setShowSearch(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClickMobileSearch = () => {
    toggleModal();
    handleClose();
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY > 10) {
      return setHeader("background-white");
    } else if (window.scrollY < 70) {
      return setHeader("");
    }
  };

  const handleSelectCategory = (value) => {
    value ? setSelectedCategory(value) : setSelectedCategory(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(`/login`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
  }, []);
  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <>
      <CustomModal title={ "Success" } hide={ toggleModal } show={ modalVisible }>
        <SelectLocation
          hideModal={ toggleModal }
          selectedCategory={ selectedCategory }
        />
      </CustomModal>{ " " }
      <div>
        <div className="d-lg-none break_point_div_header">
          <Button className="mobile-button d-lg-none" onClick={ handleShow }>
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </Button>

          <a href="/">
            <img alt="logo" className="breakpoint-logo" src={ logo } />
          </a>
          <div className="mobile-langauge">
            {/* <Select
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              className={"ms-2"}
              defaultValue={
                languageOptions[
                languageOptions.findIndex(
                  (x) => x.value === localStorage.getItem("lang")
                )
                ]
              }
              options={languageOptions}
              onChange={(e) => {
                const { title } = countries.find(
                  ({ code }) => e.value === code
                );

                changeLang(e.value);
              }}
              placeholder={"Language"}
            /> */}
            <Select
              components={ {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              } }
              className={ "ms-2" }
              defaultValue={
                languageOptions[
                languageOptions.findIndex(
                  (x) => x.value !== localStorage.getItem("lang")
                )
                ]
              }
              options={ languageOptions }
              onChange={ (e) => {
                const { title } = countries.find(
                  ({ code }) => e.value === code
                );

                changeLang(e.value);
              } }
              placeholder={ "Language" }
            />
          </div>
        </div>
        <Offcanvas
          className={ header }
          show={ show }
          backdrop={ false }
          onHide={ handleClose }
          responsive="lg"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img alt="logo" src={ logo } />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <a href="/">
              <img alt="logo" className="header-logo" src={ logo } />
            </a>
            <nav className="mobile-menu">
              <>
                { isAuthenticated && (
                  <>
                    <div className="user-div d-flex justify-content-center align-items-center">
                      <i
                        className="fa fa-bell-o p-2"
                        style={ { color: "black" } }
                      ></i>
                      { role === "Buyer" && (
                        <a href="/buyer/wishList">
                          <i className="fa fa-heart-o"></i>
                        </a>
                      ) }
                      <a
                        href={
                          role === "Buyer" ? "/buyer/chats" : "/seller/chats"
                        }
                      >
                        <i
                          className="fa fa-envelope-o p-2"
                          style={ { color: "black" } }
                        ></i>
                      </a>
                      <div className="">{ name }</div>
                    </div>
                    <ul>
                      { role === "Buyer" && (
                        <>
                          { " " }
                          <li>
                            <a className="dropdown-item" href={ "/buyer/jobs" }>
                              All Jobs
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={ "/buyer/advertiseJob" }
                            >
                              Post A Request
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={ "/buyer/all-jobs-requests" }
                            >
                              All Requests
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href={ "/buyer/chats" }>
                              Messages
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href={ "/buyer/profile" }
                            >
                              Profile Settings
                            </a>
                          </li>
                        </>
                      ) }
                      <li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={
                              role === "Buyer"
                                ? `/buyer/dashboard`
                                : `/seller/dashboard`
                            }
                          >
                            Profile
                          </a>
                        </li>
                        <a onClick={ handleLogout } href={ null }>
                          { t("Logout") }
                        </a>
                      </li>
                    </ul>
                  </>
                ) }

                { !isAuthenticated && (

                  <ul>
                    <li>
                      <a href="/">{ t("Home") }</a>
                    </li>
                    <li>
                      <a href="/about-us">{ t("About Us") }</a>
                    </li>
                    <li>
                      <a href="/search">{ t("Hire a service Provider") }</a>
                    </li>
                    <li>
                      <a href="/">{ t("Contact Us") }</a>
                    </li>
                    <li>
                      <a href="/joinAsPro">{ t("Join as a pro") }</a>
                    </li>
                    <li>
                      <a href="/register">{ t("Sign up") }</a>
                    </li>
                    <li>
                      <a href="/login">{ t("Log in") }</a>
                    </li>
                  </ul>
                ) }
              </>
            </nav>
            <div className="search_bar">
              <nav className="header__menu">
                <ul>
                  <li>
                    <a href="/">{ t("Home") }</a>
                  </li>
                  <li>
                    <a href="/about-us">{ t("About Us") }</a>
                  </li>
                  <li>
                    <a href="/search">{ t("Hire a service Provider") }</a>
                  </li>
                  <li>
                    <a href="/">{ t("Contact Us") }</a>
                  </li>
                </ul>
              </nav>
            </div>
            <nav className="header__menu">
              { !isAuthenticated && (
                <button onClick={ () => navigate(`/joinAsPro`) } className="">
                  Join as a pro
                </button>
              ) }
              <ul>
                { !isAuthenticated && (
                  <>
                    { " " }
                    <li>
                      <a href="/register">{ t("Sign up") }</a>
                    </li>
                    <li>
                      <a href="/login">{ t("Log in") }</a>
                    </li>{ " " }
                  </>
                ) }
                { isAuthenticated && (
                  <>
                    <i className="fa fa-bell-o p-2"></i>
                    { role === "Buyer" && (
                      <a href="/buyer/wishList" style={ { color: "black" } }>
                        <i className="fa fa-heart-o"></i>
                      </a>
                    ) }
                    <a
                      href={ role === "Buyer" ? "/buyer/chats" : "/seller/chats" }
                    >
                      <i
                        className="fa fa-envelope-o p-2"
                        style={ { color: "black" } }
                      ></i>
                    </a>

                    <ul>
                      <li>
                        <a href="#">
                          { name } <i className="fas fa-chevron-down" />
                        </a>
                        <ul className="header__menu__dropdown">
                          <li>
                            <a
                              className="dropdown-item"
                              href={
                                role === "Buyer"
                                  ? "/buyer/dashboard"
                                  : "/seller/dashboard"
                              }
                            >
                              Profile
                            </a>
                          </li>
                          { role === "Buyer" && (
                            <>
                              { " " }
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={ "/buyer/jobs" }
                                >
                                  All Jobs
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={ "/buyer/advertiseJob" }
                                >
                                  Post A Request
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={ "/buyer/all-jobs-requests" }
                                >
                                  All Requests
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={ "/buyer/chats" }
                                >
                                  Messages
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={ "/buyer/profile" }
                                >
                                  Profile Settings
                                </a>
                              </li>
                            </>
                          ) }
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={ handleLogout }
                              href=""
                            >
                              { t("Logout") }
                            </a>
                          </li>
                        </ul>{ " " }
                      </li>
                    </ul>
                  </>
                ) }
              </ul>

              <Select
                components={ {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                } }
                className={ "ms-2" }
                defaultValue={
                  languageOptions[
                  languageOptions.findIndex(
                    (x) => x.value !== localStorage.getItem("lang")
                  )
                  ]
                }
                options={ languageOptions }
                onChange={ (e) => {
                  const { title } = countries.find(
                    ({ code }) => e.value === code
                  );

                  changeLang(e.value);
                } }
                placeholder={ "Language" }
              />
            </nav>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="children-div">{ props.children }</div>
        <footer className="footer">
          {/* Footer Bottom */ }
          <div className="footer-bottom">
            <div className="container">
              {/* Copyright */ }
              <div className="copyright">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="copyright-text">
                      <p className="mb-0">© { t("2021 All Rights Reserved") }</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 right-text">
                    <div className="social-icon">
                      <ul>
                        <li>
                          <a href="/about-us" className="icon">
                            { t("About Us") }
                          </a>
                        </li>
                        <li>
                          <a href="/terms-and-conditions" className="icon">
                            { t("Terms and Conditions") }
                          </a>
                        </li>
                        <li>
                          <a href="privacy-policy" className="icon">
                            { t("Privacy Policy") }
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Copyright */ }
            </div>
          </div>
          {/* /Footer Bottom */ }
        </footer>
      </div>
    </>
  );
};

export default Layout;
