import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Spinner, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import Sidebar from "../../layouts/dashboard/BuyerLayout";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import {
  getProposalsReq,
  sendMessage,
  awardJobReq,
  rejectProposalReq,
} from "../../services/api";
import { useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import FloatingInputField from "../../components/FloatingInputField";
import Select from "react-select";
const Proposals = () => {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const { buyerRequestId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [sellerId, setSellerId] = useState(null);
  const [alert, setAlert] = useState(false);
  const [awardedUserId, setAwardedUserId] = useState(null);
  const [jobPostedId, setJobPostedId] = useState(null);
  const [jobPrice, setJobPrice] = useState(null);
  const [afterConfirm, setafterConfirm] = useState(false);
  const [rejectProposalAlert, setRejectPropsalAlert] = useState(false);
  const [proposalId, setProposalId] = useState(null);

  const sortOptions = [
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={{ marginRight: "10px" }}>Amount ASC</span>
        </div>
      ),
      value: "amountasc",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={{ marginRight: "10px" }}>Amount DESC</span>
        </div>
      ),
      value: "amountdesc",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={{ marginRight: "10px" }}>Ratings ASC</span>
        </div>
      ),
      value: "ratingsasc",
    },
    {
      label: (
        <div className="d-flex justify-content-center">
          <span style={{ marginRight: "10px" }}>Ratings Desc</span>
        </div>
      ),
      value: "ratingsdesc",
    },
  ];
  const getProposals = async (buyerRequestId, sort) => {
    setIsLoading(true);
    try {
      const proposals = await getProposalsReq(id, buyerRequestId, sort);
      const { data } = proposals.data;
      setProposals(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { message: "" } });

  const toggleModal = (sellerId) => {
    setSellerId(sellerId);
    setModalVisible(!modalVisible);
  };

  const handleSendMessage = async (body) => {
    setIsLoading(true);
    try {
      body.sellerId = sellerId;
      await sendMessage(id, body);

      setModalVisible(!modalVisible);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const onConfirm = async () => {
    setIsLoading(true);
    try {
      setAlert(false);
      setafterConfirm(true);
      let body = {
        awardedUserId: awardedUserId,
        jobPostedId: jobPostedId,
        price: jobPrice,
      };
      await awardJobReq(id, body);
      navigate(`/buyer/all-jobs-requests`);
    } catch (err) {
      setErrorMessage(err.response || err.response.data.message);
    }
    setIsLoading(false);
  };
  const onCancel = () => {
    setAlert(false);
    setafterConfirm(false);
    setAwardedUserId(null);
    setJobPostedId(null);
    setJobPrice(null);
    setRejectPropsalAlert(false);
    setProposalId(null);
  };

  const awardJob = (awardedUserId, jobPostedId, price) => {
    setAlert(true);
    setAwardedUserId(awardedUserId);
    setJobPostedId(jobPostedId);
    setJobPrice(price);
  };
  const onConfirmRejectProposal = async () => {
    setIsLoading(true);
    try {
      setRejectPropsalAlert(false);
      setafterConfirm(true);
      await rejectProposalReq(id, buyerRequestId, proposalId);
      navigate(`/buyer/buyerRequests/${buyerRequestId}/proposals`);
    } catch (err) {
      setErrorMessage(err.response || err.response.data.message);
    }
    setIsLoading(false);
  };
  const rejectProposal = (proposalId) => {
    setProposalId(proposalId);
    setRejectPropsalAlert(true);
  };
  const cancelRejectProsal = (proposalId) => {
    setProposalId(null);
    setRejectPropsalAlert(false);
  };

  const sortBy = (sort) => {
    getProposals(buyerRequestId, sort.value);
  };

  useEffect(() => {
    getProposals(buyerRequestId);
  }, []);
  return (
    <div>
      {afterConfirm && (
        <SweetAlert success title="Good job!" onConfirm={onCancel}>
          You clicked the button!
        </SweetAlert>
      )}
      {alert && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
          title="Award Job?"
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          You will find they are up!
        </SweetAlert>
      )}
      {rejectProposalAlert && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          customIcon="https://img.icons8.com/?size=512&id=63652&format=png"
          title="Reject Proposal ?"
          onConfirm={onConfirmRejectProposal}
          onCancel={cancelRejectProsal}
        >
          Are You sure to Reject this proposal
        </SweetAlert>
      )}
      <CustomModal title={"Success"} hide={toggleModal} show={modalVisible}>
        <div className="modal-header">
          <h4 className="modal-title">You can send Message from here !</h4>
          <span className="modal-close">
            <a
              onClick={() => setModalVisible(!modalVisible)}
              aria-label="Close"
            >
              <i className="fa fa-times-circle" />
            </a>
          </span>
        </div>
        <Form>
          <FloatingInputField
            name={"message"}
            type={"textarea"}
            placeHolder={"Message"}
            label={"Type a message"}
            control={control}
            rules={{ required: true }}
            errors={errors}
            errorMessage={"Please type message"}
          />
          <Button onClick={handleSubmit(handleSendMessage)} type="submit">
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Send Message"
            )}
          </Button>
        </Form>
      </CustomModal>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="page-title" style={{ paddingBottom: "10px" }}>
                    <span className="heading_2" style={{ fontSize: "24px" }}>
                      Manage Proposals
                    </span>

                    <div className="filter_dropdown pull-right">
                      <Select
                        className={"ms-2"}
                        defaultValue={"sortBy"}
                        options={sortOptions}
                        onChange={sortBy}
                        placeholder={"Sort By"}
                      />
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="my-projects-view">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pro-post mb-4">
                          <div className="project-table">
                            <div className="table-responsive">
                              <table className="table table-hover table-center mb-0 datatable">
                                <thead className="thead-pink">
                                  <tr>
                                    <th>Seller Detail</th>
                                    <th>Bidding Amount</th>
                                    <th>Notes</th>
                                    <th>Date/Time</th>
                                    <th>Distance</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {proposals.length > 0 ? (
                                    proposals.map((proposal) => (
                                      <tr>
                                        <td>
                                          <h2 className="table-avatar">
                                            {proposal.appliedBy?.profilePic && (
                                              <Link
                                                to="#"
                                                className="avatar avatar-md tab-imgcircle me-2"
                                              >
                                                <img
                                                  className="avatar-img rounded-circle"
                                                  src={
                                                    proposal.appliedBy
                                                      ?.profilePic
                                                  }
                                                  alt="User Image"
                                                />
                                              </Link>
                                            )}
                                            <Link to="#">
                                              <span className="profile-name">
                                                {proposal.appliedBy?.name}
                                              </span>
                                              {/* <span>UI/UX Designer</span> */}
                                              <span className="rating mt-2">
                                                {proposal?.appliedBy?.reviews >
                                                  0 &&
                                                  `${proposal?.appliedBy?.reviews} `}
                                                {proposal.appliedBy?.reviews > 0
                                                  ? [
                                                      ...Array(
                                                        proposal?.appliedBy
                                                          ?.reviews
                                                      ),
                                                    ].map(() => (
                                                      <i className="fas fa-star filled" />
                                                    ))
                                                  : "No rating"}
                                              </span>
                                            </Link>
                                          </h2>
                                        </td>
                                        <td>${proposal.bidding_amount}</td>
                                        <td>{proposal.notes}</td>
                                        <td>
                                          <h2 className="table-avatar">
                                            {new Date(
                                              proposal.createdAt
                                            ).toGMTString()}
                                          </h2>
                                        </td>
                                        <td>
                                          {proposal.appliedBy?.services[0]
                                            ?.distance
                                            ? `${Math.floor(
                                                proposal.appliedBy?.services[0]
                                                  ?.distance
                                              )} Km away`
                                            : "Near you"}
                                        </td>

                                        <td className="p-1 m-2">
                                          {/* <a href="#" className="fav">
                                  <i className="fas fa-envelope filled" />
                                </a> */}
                                          <Button
                                            className="btn btn-sm btn-info"
                                            onClick={() =>
                                              toggleModal(proposal.fk_user_id)
                                            }
                                          >
                                            <i className="fas fa-envelope filled" />
                                            &nbsp;Message
                                          </Button>
                                          &nbsp;
                                          <Button
                                            className="btn btn-sm btn-success"
                                            onClick={() =>
                                              awardJob(
                                                proposal.fk_user_id,
                                                proposal.fk_posted_id,
                                                proposal.bidding_amount
                                              )
                                            }
                                          >
                                            <i className="fas fa-star" /> Award
                                          </Button>
                                          &nbsp;
                                          <Button
                                            className="btn btn-sm btn-warning"
                                            onClick={() =>
                                              rejectProposal(proposal.id)
                                            }
                                          >
                                            <i className="fas fa-close" />
                                            Reject
                                          </Button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>No Proposal</tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
};

export default Proposals;
