import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import placeHolder from "../../assets/images/andriod.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "../../components/Carousel/ButtonGroup";
import { getServiceDetailReq } from "../../services/api";
import Loading from "../../components/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";
import ErrorAlert from "../../components/ErrorAlert";
import Cookies from "js-cookie";
const ServiceDetails = () => {
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let recentlyViewed = Cookies.get("recentlyViewed")
    ? JSON.parse(Cookies.get("recentlyViewed"))
    : [];
  let { serviceId } = useParams();

  const getServiceDetail = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedDetails } = await getServiceDetailReq(serviceId);
      setDetails(fetchedDetails.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const index = recentlyViewed.findIndex((id) => {
      return id === serviceId;
    });
    if (index === -1) {
      recentlyViewed = [...recentlyViewed, serviceId];
      Cookies.set("recentlyViewed", JSON.stringify(recentlyViewed));
    }
  }, []);
  useEffect(() => {
    getServiceDetail();
  }, []);
  return (
    <>
      {isLoading && <Loading />}
      <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
      <Layout>
        <div className="service_details_container">
          <div className="details_container">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile">
                      <div className="profile-box">
                        <div className="provider-widget row">
                          <div className="pro-info-left col-md-8">
                            <div className="profile-info">
                              <h2 className="profile-title">
                                {details?.title}
                              </h2>
                              <p className="profile-position">
                                {details?.category?.title}
                              </p>
                              <div />
                              <ul className="profile-preword align-items-center">
                                <li>
                                  <i className="fas fa-clock" /> Posted{" "}
                                  {moment(details?.createdAt)
                                    .startOf("minute")
                                    .fromNow()}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="pro-info-right profile-inf col-md-4">
                            {/* <ul className="profile-right">
                              <li>
                                <div className="amt-hr">${details?.price}</div>
                              </li>
                            </ul> */}
                            {/* <div className="d-flex align-items-center justify-content-md-end justify-content-center">
                              <a href="">
                                <i className="fas fa-heart heart fa-2x me-2 orange-text" />
                              </a>
                              <a
                                data-bs-toggle="modal"
                                href="#file"
                                className="btn bid-btn"
                              >
                                Send Proposal{" "}
                                <i className="fas fa-long-arrow-alt-right" />
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="pro-view">
                      <div className="pro-post project-widget widget-box">
                        <h3 className="pro-title">Skills</h3>
                        <div className="pro-content">
                          <div className="tags">
                            {details?.serviceSkills.length > 0
                              ? details?.serviceSkills.map((skills, key) => {
                                  return (
                                    <a key={key} href="">
                                      <span className="badge badge-pill badge-design">
                                        {skills.skill.title}
                                      </span>
                                    </a>
                                  );
                                })
                              : "No skill added by the provider"}
                          </div>
                        </div>
                      </div>
                      {/* Senior Animator  */}
                      <div className="pro-post widget-box">
                        <h3 className="pro-title">Description</h3>
                        <div
                          className="pro-content"
                          dangerouslySetInnerHTML={{
                            __html: details?.description,
                          }}
                        ></div>
                      </div>
                      <div
                        className="pro-post project-widget widget-box"
                        id="project"
                      >
                        <h3 className="pro-title">Images</h3>

                        <div className="image_container">
                          {details?.images.length > 0
                            ? details?.images.map((image, key) => {
                                return <img key={key} src={image.url} />;
                              })
                            : "No images added by the provider"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Content */}
            <></>
          </div>
        </div>{" "}
      </Layout>
    </>
  );
};

export default ServiceDetails;
