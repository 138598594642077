import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Proposals = (props) => {
  const { t } = useTranslation();
  const proposals = props?.data;

  return (
    <>
      <div className="my-projects-view mt-2">
        <div className="row">
          <div className="col-lg-12">
            <div className="pro-post mb-4">
              <div className="project-table">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0 datatable">
                    <thead className="thead-pink">
                      <tr>
                        <th>Project name</th>
                        <th>Client Detail</th>
                        <th>Offered Amount</th>
                        <th>Bidding Amount</th>
                        <th>Bidding Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {proposals.length > 0 ? (
                        proposals.map((proposal) => (
                          <tr>
                            <td>
                              <h6>{proposal.jobPosted?.title}</h6>
                              {proposal.jobPosted?.description}
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                {proposal.jobPosted?.createdBy?.profilePic && (
                                  <img
                                    className="avatar-img rounded-circle"
                                    style={{
                                      width: "100px",
                                    }}
                                    src={
                                      proposal.jobPosted?.createdBy?.profilePic
                                    }
                                    alt="User Image"
                                  />
                                )}
                                <Link to="">
                                  <span className="profile-name">
                                    {proposal.jobPosted?.createdBy?.name}
                                  </span>
                                  <span>
                                    {proposal.jobPosted?.createdBy?.status}
                                  </span>
                                  <span className="rating mt-2">
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                  </span>
                                </Link>
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                ${proposal.jobPosted?.offer_amount}
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                ${proposal.bidding_amount}
                              </h2>
                            </td>
                            <td>
                              <h2 className="table-avatar">
                                {new Date(proposal.createdAt).toLocaleString()}
                              </h2>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>No Proposal</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Proposals;
