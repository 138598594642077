import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import logo from "../../assets/images/logo.png";
import FloatingInputField from "../../components/FloatingInputField";
import { useForm } from "react-hook-form";
import { loginReq, loginSocialReq } from "../../services/api";
import { useDispatch } from "react-redux";
import ErrorAlert from "../../components/ErrorAlert";
import { login } from "../../store/features/authSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

const Login = () => {
  const { t, i18n } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { email: "", password: "" } });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [googleBody, setGoogleBody] = useState(null);
  const [provider, setProvider] = useState(null);
  const videoUrl = `${process.env.REACT_APP_BASE_URL}/video?lang=${i18n.language}`

  const userLogin = async (body) => {
    setIsLoading(true);
    try {
      const { data: user } = await loginReq(body);
      dispatch(login(user.data));
      if (user.data.user.role === "Seller") {
        navigate(`/seller/dashboard`);
      } else if (user.data.user.role === "Buyer") {
        navigate(`/buyer/dashboard`);
      }
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onLogin = (values) => {
    const body = {
      username: values.email,
      password: values.password,
    };
    userLogin(body);
  };
  const socialLogin = async (data) => {
    try {
      const user = await loginSocialReq({
        username: data.username || data.email,
      });
      if (user && user.data && user.data.success) {
        dispatch(login(user.data.data));
        const url =
          user.data.data.user.role === "Buyer"
            ? `/buyer/dashboard`
            : `/seller/dashboard`;
        navigate(url);
        return true;
      }
      if (user.status === 204) {
        setErrorMessage("Please register yourself first");
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <>
      <ErrorAlert message={ errorMessage } setErrorMessage={ setErrorMessage } />
      <div className="content">
        <div className="row">
          <div className="col-lg-6 signup_banner">
            <div className="signup-overlay"></div>
          </div>
          <div className="col-md-6 login_right">
            {/* Login Content */ }
            <div className="account-content">
              <div className="align-items-center justify-content-center">
                <div className="login-right">
                  <div className="login-header text-center">
                    <Link to="/">
                      <img src={ logo } alt="logo" className="img-fluid" />
                    </Link>
                    <h3>{ t("Welcome Back") }</h3>
                  </div>
                </div>
                <Form>
                  <FloatingInputField
                    name={ "email" }
                    type={ "text" }
                    placeHolder={ "Enter Email" }
                    label={ "Email Address or Username" }
                    control={ control }
                    rules={ { required: true } }
                    errors={ errors }
                    errorMessage={ "Email is required" }
                  />
                  {/* <input type="text" name="" placeholder={t("Email Address or Username")} /> */ }
                  <FloatingInputField
                    name={ "password" }
                    type={ "password" }
                    placeHolder={ t("Enter Password") }
                    label={ t("Password") }
                    control={ control }
                    rules={ { required: true } }
                    errors={ errors }
                    errorMessage={ t("Password is required") }
                  />
                  <Form.Check
                    name={ "Remember me" }
                    type={ "checkbox" }
                    id={ `default-${"checkbox"}` }
                    label={ "Remember me" }
                  />
                  <button
                    className="login-btn"
                    onClick={ handleSubmit(onLogin) }
                    type="submit"
                  >
                    { isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Login"
                    ) }
                  </button>
                  <div className="row">
                    <div className="col-6 text-start">
                      <Link className="forgot-link" to="">
                        { t("Forgot Password ?") }
                      </Link>
                    </div>
                  </div>
                  <div className="row socials signup-social">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      {/* <LoginSocialGoogle
                        client_id={ process.env.REACT_APP_GG_APP_ID || "" }
                        scope={ "email" }
                        // onLoginStart={onLoginStart}
                        onResolve={ ({ provider, data }) => {
                          setProvider(provider);
                          setGoogleBody(data);
                          socialLogin(data);
                        } }
                        onReject={ (err) => {
                          console.log(err);
                        } }
                      > */}
                      <button className="social-login-google" type="button">
                        <i className="fa fa-google m-2"></i>
                        { isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          t("Login With Google")
                        ) }
                      </button>
                      {/* </LoginSocialGoogle> */ }
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <LoginSocialFacebook
                        appId={ process.env.REACT_APP_FB_APP_ID || "" }
                        onResolve={ ({ provider, data }) => {
                          setProvider(provider);
                          setGoogleBody(data);
                          socialLogin(data);
                        } }
                        onReject={ (err) => {
                          console.log(err);
                        } }
                      >
                        <button className="social-login-facebook" type="button">
                          <i className="fa fa-facebook m-2"></i>
                          { isLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            t("Login With Facebook")
                          ) }
                        </button>
                      </LoginSocialFacebook>
                    </div>

                    <div className="col-6 text-center dont-have">
                      { t("New to Wizafri?") }{ " " }
                      <Link to="/register">{ t("Click here") }</Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          {/* /Login Content */ }
        </div>
      </div>
    </>
  );
};
export default Login;
