import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ErrorAlert from "../../components/ErrorAlert";

const Map = ({ users, center, activeMarker }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [mapWidth, setMapWidth] = useState({
    height: "73vh",
    width: "45vw",
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEAOhFgtiWHQMMBP1XUkxGjjWLpjqsnLQ",
    libraries: ["drawing"],
  });
  let services =
    users &&
    users.map((us) => {
      return us.services;
    });

  const handleMouseOver = (serviceId) => {
    setShowInfoWindow(serviceId);
  };
  const handleMouseExit = () => {
    setShowInfoWindow(null);
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setMapWidth({
        height: "73vh",
        width: "100vw",
      });
    } else {
      setMapWidth({
        height: "73vh",
        width: "45vw",
      });
    }
  }, [window.innerWidth]);

  return isLoaded ? (
    <>
      <ErrorAlert message={errorMessage} setIsError={setErrorMessage} />
      <div>
        {" "}
        <GoogleMap mapContainerStyle={mapWidth} center={center} zoom={6.5}>
          {services &&
            services.flat().length > 0 &&
            services.flat().map((service, key) => {
              return activeMarker === service.fk_user_id ? (
                <Marker
                  key={key}
                  onLoad={() => null}
                  position={{
                    lat: Number(service.lat),
                    lng: Number(service.lng),
                  }}
                  icon={"http://maps.google.com/mapfiles/ms/icons/blue.png"}
                  onMouseOver={() => handleMouseOver(service.id)}
                  onMouseOut={handleMouseExit}
                >
                  {showInfoWindow && service.id === showInfoWindow && (
                    <InfoWindow onLoad={() => null}>
                      <div>
                        <p className="m-0 p-0">{service?.title}</p>
                        <p
                          className="m-0 p-0"
                          dangerouslySetInnerHTML={{
                            __html: service?.description,
                          }}
                        ></p>
                        <p className="m-0 p-0">{service?.price}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ) : (
                <MarkerF
                  key={key}
                  onLoad={() => null}
                  position={{
                    lat: Number(service.lat),
                    lng: Number(service.lng),
                  }}
                  onMouseOver={() => handleMouseOver(service.id)}
                  onMouseOut={handleMouseExit}
                >
                  {showInfoWindow && service.id === showInfoWindow && (
                    <InfoWindow onLoad={() => null}>
                      <div>
                        <p className="m-0 p-0">{service?.title}</p>
                        <p
                          className="m-0 p-0"
                          dangerouslySetInnerHTML={{
                            __html: service?.description,
                          }}
                        ></p>
                        <p className="m-0 p-0">{service?.price}</p>
                      </div>
                    </InfoWindow>
                  )}
                </MarkerF>
              );
            })}
        </GoogleMap>
      </div>
    </>
  ) : (
    <div className="text-center">Could not Load Map</div>
  );
};

export default Map;
