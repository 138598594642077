import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import About_img from "../../assets/images/HomeBanner.jpeg";
import { getTermsAndConditionReq } from "../../services/api";
import { Link } from "react-router-dom";
import Loading from "../../components/Loader";
import ErrorAlert from "../../components/ErrorAlert";

const TermsAndConditions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const aboutUs = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getTermsAndConditionReq();
      setData(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    aboutUs();
  }, []);
  return (
    <>
      {isLoading && <Loading />}
      <Layout>
        <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
        {/* Page Content */}
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Terms and Conditions</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {" "}
                      Terms and Conditions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section about">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="about-content">
                  <h2>
                    Our{" "}
                    <span className="orange-text">Terms and Conditions</span>
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data && data.description,
                    }}
                  ></p>
                </div>
              </div>
              <div className="offset-lg-1 col-lg-5">
                <div className="about-img">
                  <img className="img-fluid" src={About_img} alt="Post Image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Page Content */}
      </Layout>
    </>
  );
};

export default TermsAndConditions;
