import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Jobs from "../../components/jobs/jobs";
import { getSellerJobsReq } from "../../services/api/seller";
import { useSelector } from "react-redux";

function Index(props) {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [sellerJobs, setSellerJobs] = useState([]);
  const getSellerJobs = async (status) => {
    setIsLoading(true);
    try {
      const requests = await getSellerJobsReq(id, status);
      const { data } = requests.data;
      setSellerJobs(data);
    } catch (err) {
      setErrorMessage(err.message || err.response.data.message);
    }
    setIsLoading(false);
  };
  const handleSelect = (key) => {
    if (key === "In Progress") {
      getSellerJobs("In Progress");
    } else if (key === "Completed") {
      getSellerJobs("Completed");
    } else if (key === "Cancelled") {
      getSellerJobs("Cancelled");
    }
  };
  useEffect(() => {
    getSellerJobs("In Progress");
  }, []);
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="page-title">
                    <h3>Manage Jobs</h3>
                  </div>
                  <Tabs
                    defaultActiveKey="In Progress"
                    className="nav nav-tabs nav-tabs-bottom nav-justified"
                    id="controlled-tabs"
                    selectedTabClassName="bg-white"
                    onSelect={handleSelect}
                  >
                    <Tab
                      className="nav-item"
                      eventKey="In Progress"
                      title="In Progress"
                    >
                      <Jobs data={sellerJobs} />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="Completed"
                      title="Completed"
                    >
                      <Jobs data={sellerJobs} />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="Cancelled"
                      title="Cancelled"
                    >
                      <Jobs data={sellerJobs} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Index;
