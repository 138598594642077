import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import ApexCharts from "apexcharts";
import nodejs from "../../assets/images/nodejs.png";
import { getSellerServices } from "../../services/api";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import Service from "../../components/Service";

const Index = () => {
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [services, setServices] = useState([]);

  const sellerServices = async () => {
    setIsLoading(true);
    try {
      const { data: userSerices } = await getSellerServices(id);
      setServices(userSerices.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    sellerServices();
  }, []);

  return (
    <div>
      <Layout>
        <>
          <section className="content-dashboard">
            {/* Page Content */}
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-md-12 col-lg-8 col-xl-9">
                  <div className="sort-tab">
                    <div className="row align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="d-flex align-items-center">
                          <div className="freelance-view">
                            <h4>My Services</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="d-flex justify-content-sm-end">
                          <div className="sort-by">
                            <select className="custom-select">
                              <option>No options</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ color: "red" }}>
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <div className="row service-scrollable">
                        {/* Project Content */}
                        {services.length > 0 ? (
                          services.map((service, key) => (
                            <Service data={service} />
                          ))
                        ) : (
                          <div className="freelance-view">
                            <h4>No Services</h4>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Content */}
          </section>
        </>
      </Layout>
    </div>
  );
};

export default Index;
