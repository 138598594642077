import styled from "styled-components";
export const Label = styled.label`
  z-index: 2;
  opacity: ${(props) => (props.isFloating ? `0.65` : `1`)};
  transform: ${(props) =>
    props.isFloating
      ? `scale(0.85) translateY(-0.5rem) translateX(0.15rem)`
      : `0`};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
`;

export const THEME_COLORS = {
  primary: "#2F7932",
  secondary: "#8CC63F",
  secondary_light: "#8cc63f40",
  black: "#000000",
  white: "#ffffff",
};

export const customSelectStyles = {
  option: (provided, state) => {
    const selectedOptionColor = THEME_COLORS.black;
    const selectedOptionBackgroundColor = THEME_COLORS.secondary;
    const selectedOptionColorLight = THEME_COLORS.black;
    const selectedOptionBackgroundColorLight = THEME_COLORS.secondary_light;

    return {
      ...provided,
      height: 35,
      zIndex: 10,
      color: selectedOptionColor,
      backgroundColor: state.isSelected && selectedOptionBackgroundColor,
      "&:hover": {
        color: state.isSelected
          ? selectedOptionColor
          : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
      "&:focus": {
        color: state.isSelected
          ? selectedOptionColor
          : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
      "&:active": {
        color: state.isSelected
          ? selectedOptionColor
          : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
    };
  },
};

export const IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];
