import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/dashboard/BuyerLayout";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BuyerRequests from "../../components/BuyerRequests/BuyerRequests";
import { getBuyerRequest } from "../../services/api";
import { useSelector } from "react-redux";

function AllBuyerRequests() {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buyerRequests, setBuyerRequests] = useState([]);
  const getBuyerRequests = async (status) => {
    setIsLoading(true);
    try {
      const requests = await getBuyerRequest(id, status);
      const { data } = requests.data;
      setBuyerRequests(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const handleSelect = (key) => {
    getBuyerRequests(key);
  };
  useEffect(() => {
    getBuyerRequests("active");
  }, []);
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="page-title">
                    <h3>Request Status</h3>
                  </div>
                  <Tabs
                    defaultActiveKey="Active"
                    className="nav nav-tabs nav-tabs-bottom nav-justified"
                    id="controlled-tabs"
                    selectedTabClassName="bg-white"
                    onSelect={handleSelect}
                  >
                    <Tab className="nav-item" eventKey="Active" title="Active">
                      {!isLoading && <BuyerRequests data={buyerRequests} />}
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="in-active"
                      title="Cancelled"
                    >
                      {!isLoading && <BuyerRequests data={buyerRequests} />}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default AllBuyerRequests;
