import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createWishList } from "../services/api";
import { updateProfile } from "../store/features/authSlice";

const Service = (props) => {
  const services = props.data;
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [errorMessageWishList, setErrorMessage] = useState(null);
  const [sIds, setIds] = useState(user.wishlisted_services || []);
  const dispatch = useDispatch();

  const addToWishList = async (serviceId) => {
    try {
      if (!user) {
        setErrorMessage("Please Login");
        return;
      }

      if (user.role === "Seller") {
        setErrorMessage("Not Eligible");
        return;
      }

      const {
        data: { data },
      } = await createWishList(user.id, serviceId);
      dispatch(updateProfile({ user: data }));
      setIds([...sIds, serviceId]);
    } catch (err) {
      console.error("Error adding to wishlist:", err);
    }
  };

  const isWishlisted = sIds.includes(services.id);

  const renderMessageButton = () => {
    if (user && user.role === "Buyer") {
      return (
        <button onClick={() => navigate(`/messages/${services.user.id}`)}>
          Message
        </button>
      );
    }
    return null;
  };

  return (
    <div className="col-md-3 col-sm-12 col-xs-12 pointer-cursor">
      <div className="service-image">
        <a href={`/serviceDetails/${services.id}`} className="image-link">
          <div className="DIV_3">
            <img
              src={services?.images[0]?.url}
              alt={services?.title}
              className="service-image-show"
            />
          </div>
        </a>
        <div>
          <div className="name-category">
            <figure className="figure">
              <img src={services?.user?.profilePic} className="IMG_14" />
            </figure>
            <div className="DIV_15">
              <div>
                <a
                  href={null}
                  rel="nofollow noopener noreferrer"
                  className="seller-name"
                >
                  {services?.user?.name}
                </a>
              </div>
              <div>
                <span className="seller-category">
                  {services?.category?.title}
                </span>
              </div>
            </div>
          </div>
        </div>
        <h3 className="h3-div">
          <a href={null} className="service-title">
            {services?.title}
          </a>
        </h3>
        <div className="reviews-div">
          <i className="fa fa-star"></i>{" "}
          {services?.user?.reviews ? services?.user.reviews : "No ratings"}
        </div>
        <footer className="footer-service">
          <div className="footer-div pb-2">
            <button onClick={() => addToWishList(services.id)}>
              <i className={isWishlisted ? "fa fa-heart" : "fa fa-heart-o"}></i>
            </button>
            {errorMessageWishList && <span>{errorMessageWishList}</span>}
            {renderMessageButton()}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Service;
