import React from "react";
import { Route, Routes } from "react-router-dom";

import JobsRequests from "../pages/BuyerDashboard/jobs_requests";
import AllBuyerRequests from "../pages/BuyerDashboard/AllBuyerRequests";
import Proposals from "../pages/BuyerDashboard/Proposal";
import Jobs from "../pages/BuyerDashboard/jobs";
import Reviews from "../pages/BuyerDashboard/reviews";
import Chat from "../pages/BuyerDashboard/chat";
import AdvertiseJob from "../pages/BuyerDashboard/AdvertiseJob";
import Dashboard from "../pages/BuyerDashboard/Dashboard";
import Profile from "../pages/BuyerDashboard/Profile";
import WishList from "../pages/BuyerDashboard/WishList";

const BuyerNavigators = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/advertiseJob" element={<AdvertiseJob />} />
      <Route path="/jobs-requests" element={<JobsRequests />} />
      <Route path="/all-jobs-requests" element={<AllBuyerRequests />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/chats" element={<Chat />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/wishList" element={<WishList />} />
      <Route
        path="/buyerRequests/:buyerRequestId/proposals"
        element={<Proposals />}
      />
    </Routes>
  );
};
export default BuyerNavigators;
