import React from "react";
import { useSelector } from "react-redux";

//React-router-dom
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
} from "react-router-dom";

//Components
import Home from "../pages/home";
import Error from "../pages/error";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import SearchPage from "../pages/SearchPage/Searchpage";
import JoinAsPro from "../pages/Register/JoinAsPro";
import SellerNavigators from "./SellerNavigators";
import BuyerNavigators from "./buyerNavigators";
import ProviderDetails from "../pages/Provider Details";
import ServiceDetails from "../pages/ProviderServices/ServiceDetails";

// Common
import AboutUs from "../pages/Common/About-us";
import PrivacyPolicy from "../pages/Common/Privacy-policy";
import TermsAndConditions from "../pages/Common/Terms-and-conditions";

const ProtectedRoutesSellers = () => {
  const state = useSelector((state) => state);
  const { isAuthenticated, user } = state.auth;
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  } else if (user.role != "Seller") {
    return <Navigate to="/" replace />;
  } else {
    return <Outlet />;
  }
};
const ProtectedRoutesBuyers = () => {
  const state = useSelector((state) => state);
  const { isAuthenticated, user } = state.auth;
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  } else if (user.role != "Buyer") {
    return <Navigate to="/" replace />;
  } else {
    return <Outlet />;
  }
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/joinAsPro" element={<JoinAsPro />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/providerDetails/:providerId"
        element={<ProviderDetails />}
      />
      <Route path="/serviceDetails/:serviceId" element={<ServiceDetails />} />
      <Route element={<ProtectedRoutesSellers />}>
        <Route path="seller/*" element={<SellerNavigators />} />
      </Route>
      <Route element={<ProtectedRoutesBuyers />}>
        <Route path="buyer/*" element={<BuyerNavigators />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Route>
  )
);

const Navigation = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default Navigation;
