import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";

import ChatList from "../../components/Chat/ChatList";

// get Chat Lists
import { chatListsReq } from "../../services/api";
import { useSelector } from "react-redux";

function Index(props) {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errormessage, setErrorMessage] = useState(null);
  const [chatLists, setChatLists] = useState([]);
  const [roomUnreadMsgs, setRoomUnreadMsgs] = useState([]);

  const getChats = async (id) => {
    setIsLoading(true);
    try {
      const response = await chatListsReq(id);
      const { rooms, unReadMsgs } = response.data?.data;
      setChatLists(rooms);
      setRoomUnreadMsgs(unReadMsgs);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getChats(id);
  }, []);
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard pb-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="chat-window">
                    <ChatList
                      data={chatLists}
                      unReadMsgs={roomUnreadMsgs}
                      key={`${chatLists.length}_chatList`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Index;
