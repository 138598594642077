import React from "react";
import { Route, Routes } from "react-router-dom";

import Services from "../pages/SellerDashboard/services";
import JobsRequests from "../pages/SellerDashboard/jobs_requests";
import Jobs from "../pages/SellerDashboard/jobs";
import Reviews from "../pages/SellerDashboard/reviews";
import Chat from "../pages/SellerDashboard/chat";
import AddService from "../pages/SellerDashboard/AddService";
import Dashboard from "../pages/SellerDashboard/Dashboard";
import Profile from "../pages/SellerDashboard/Profile";

const SellerNavigators = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/addService" element={<AddService />} />
      <Route path="/services" element={<Services />} />
      <Route path="/jobs-requests" element={<JobsRequests />} />
      <Route path="/jobs-requests" element={<JobsRequests />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/chats" element={<Chat />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
};
export default SellerNavigators;
