function Index(props) {
  return (
    <div>
      <h1 className="error-text">PAGE NOT FOUND</h1>
      <p className="navigation-text">Home Page</p>
    </div>
  );
}

export default Index;
