import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import Filters from "./Filters";
import Map from "./Map";
import {
  getCountriesReq,
  getCitiesReq,
  getCategoriesListReq,
  searchReq,
  getLocationsReq,
} from "../../services/api";
import { useSearchParams } from "react-router-dom";
import FreelanceWidget from "./FreelanceWidget";
import { Spinner } from "react-bootstrap";
import ErrorAlert from "../../components/ErrorAlert";

const SearchPage = () => {
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 7.5455112, lng: -5.547545 });

  let categoryId = searchParams.get("categoryId");
  let countryId = searchParams.get("countryId");
  let cityId = searchParams.get("cityId");
  let locationId = searchParams.get("locationId");

  let pageLimit = 5;

  const getServices = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedServices } = await searchReq(
        categoryId,
        1,
        cityId,
        locationId,
        pageLimit,
        pageNumber
      );
      setUsers((currentState) => {
        return [...currentState, ...fetchedServices.data.users];
      });
    } catch (err) {
      console.log("err", err);
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const setCategoryId = (category) => {
    categoryId = category.value;
  };

  const setCountryId = (country) => {
    getCities(country.value, 1);
    countryId = country.value;
  };
  const setCityId = (city) => {
    getLocations(city.value);
    cityId = city.value;
  };
  const setLocationId = (location) => {
    locationId = location.value;
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCountries } = await getCountriesReq();
      const countries = fetchedCountries.data.map((country) => {
        return {
          label: country.title,
          value: country.id,
        };
      });
      getCities(1, 1);
      setCountries(countries);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCities = async (countryId, stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedCities } = await getCitiesReq(countryId, stateId);
      const cities = fetchedCities.data.map((city) => {
        return {
          label: city.title,
          value: city.id,
        };
      });
      setCities(cities);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getLocations = async (stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedLocations } = await getLocationsReq(stateId);
      const locations = fetchedLocations.data.map((location) => {
        return {
          label: location.title,
          value: location.id,
        };
      });
      setLocations(locations);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const onHandleSearch = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedServices } = await searchReq(
        categoryId,
        1,
        cityId,
        locationId,
        pageLimit,
        pageNumber
      );
      setUsers(fetchedServices.data.users);
    } catch (err) {
      console.log("err", err);
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPageNumber((currentState) => {
        return currentState + 1;
      });
      getServices();
    }
  };

  useEffect(() => {
    getCategories();
    getCountries();
    getServices();
    if (countryId) {
      getCities(countryId, 1);
    }
    if (cityId) {
      getLocations(cityId);
    }
  }, []);

  return (
    <>
      <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
      <Layout>
        <div className="search-page">
          <Filters
            categories={categories}
            countries={countries}
            cities={cities}
            locations={locations}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            countryId={countryId}
            cityId={cityId}
            locationId={locationId}
            setCountryId={setCountryId}
            setCityId={setCityId}
            setLocationId={setLocationId}
            onHandleSearch={onHandleSearch}
          />
          <div className="row m-0 p-0">
            <div
              className="col-lg-6 col-md-12 col-sm-12 order-sm-2 order-xs-2 order-md-2 order-lg-1 results-container"
              onScroll={onHandleScroll}
            >
              {" "}
              {users.length > 0
                ? users.map((user, key) => {
                    return (
                      <FreelanceWidget
                        key={key}
                        user={user}
                        setActiveMarker={setActiveMarker}
                      />
                    );
                  })
                : "No Services Available"}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 order-sm-1 order-xs-1 order-md-1 order-lg-2 map-container">
              <Map users={users} activeMarker={activeMarker} center={center} />
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" size="md" />
          </div>
        )}
      </Layout>
    </>
  );
};

export default SearchPage;
