import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import logo from "../../assets/images/logo.png";
import FloatingInputField from "../../components/FloatingInputField";
import CustomModal from "../../components/CustomModal";
import { useForm } from "react-hook-form";
import FloatLabelSelectDropDown from "../../components/FloatingSelectInput";
import { customSelectStyles, IMAGE_FORMATS } from "../../constants";
import ErrorAlert from "../../components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/features/authSlice";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  getCategoriesListReq,
  getCountriesReq,
  getCitiesReq,
  registerUserReq,
  registerUserSocial,
  loginSocialReq,
  getLocationsReq,
} from "../../services/api";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";

const JoinAsPro = () => {
  const { t, i18n } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      cellNumber: "",
      title: "",
      price: 0,
    },
  });
  const videoUrl = `${process.env.REACT_APP_BASE_URL}/video?lang=${i18n.language}`

  const [states, setStates] = useState([]);
  const [screen, setScreen] = useState("reg");
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [imageError, setImageError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationsSocial, setLocationsSocial] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [catModalVisible, setCatModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [servicesArray, setServicesArray] = useState([]);
  const [servicesArrayModal, setServicesArrayModal] = useState([]);
  const [googleBody, setGoogleBody] = useState(null);
  const [provider, setProvider] = useState(null);
  const dispatch = useDispatch();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [geoLocation, setGeoLocation] = useState({
    lat: "123456",
    long: "123456",
  });
  const navigate = useNavigate();

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const socialLogin = async (data) => {
    const user = await loginSocialReq({
      username: data.username || data.email,
    });
    if (user && user.data && user.data.success) {
      dispatch(login(user.data.data));
      navigate(`/seller/dashboard`);
    } else {
      return false;
    }
  };
  const categoriesModal = async (data) => {
    const status = await socialLogin(data);
    !status && setCatModalVisible(!catModalVisible);
  };

  const registerUser = async (body) => {
    setIsLoading(true);
    try {
      await registerUserReq(body);
      toggleModal();
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const registerGoogleUser = async (body, provider) => {
    setIsLoading(true);
    try {
      const { data } = await registerUserSocial(body, provider);
      console.log(data);
      if (data.success) {
        console.log(data.user, "======>");
        await socialLogin(data.data.user);
      }
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleSelectCategory = (values) => {
    setValue("category", {
      label: values.label,
      value: values.value,
    });
    clearErrors("category");
  };
  const handleModalSelectCategory = (values) => {
    if (values.length < 1) {
      setError("servicesModal");
    } else {
      clearErrors("servicesModal");
    }
    setValue("servicesModal", values);
    setServicesArrayModal(
      values.map((value) => {
        return value.value;
      })
    );
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCountries } = await getCountriesReq();
      const countries = fetchedCountries.data.map((country) => {
        return {
          label: country.title,
          value: country.id,
        };
      });
      getCities(countries[0].value, 1);
      setValue("country", {
        label: countries[0].title,
        value: countries[0].value,
      });
      setSelectedCountryId(countries[0].value);
      setCountries(countries);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getLocations = async (stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedLocations } = await getLocationsReq(stateId);
      const locations = fetchedLocations.data.map((location) => {
        return {
          label: location.title,
          value: location.id,
        };
      });
      setLocations(locations);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  // const getStates = async (countryId) => {
  //   setIsLoading(true);
  //   try {
  //     const { data: fetchedStates } = await getStatesReq(countryId);
  //     const states = fetchedStates.data.map((state) => {
  //       return {
  //         label: state.title,
  //         value: state.id,
  //       };
  //     });
  //     setStates(states);
  //   } catch (err) {
  //     setErrorMessage(err.response.data.message);
  //   }
  //   setIsLoading(false);
  // };

  const getCities = async (countryId, stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedCities } = await getCitiesReq(countryId, stateId);
      const cities = fetchedCities.data.map((city) => {
        return {
          label: city.title,
          value: city.id,
        };
      });
      setCities(cities);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleAgreement = (event) => {
    event.target.checked ? setIsDisabled(false) : setIsDisabled(true);
  };
  const onHandleAgreementService = (event) => {
    event.target.checked ? setIsDisabled(false) : setIsDisabled(true);
  };
  const handleSelectCountry = (values) => {
    setSelectedCountryId(values.value);
    getCities(values.value, 1);
    setValue("country", {
      label: values.label,
      value: values.value,
    });
    clearErrors("country");
  };

  const onHandleSelectState = (values) => {
    getCities(selectedCountryId, values.value);
    setValue("state", {
      label: values.label,
      value: values.value,
    });
    clearErrors("state");
  };

  const onHandleSelectCity = (values) => {
    setValue("city", {
      label: values.label,
      value: values.value,
    });
    clearErrors("city");
    getLocations(values.value);
  };

  const onHandleSelectLocation = (values) => {
    setValue("locationofProvider", {
      label: values.label,
      value: values.value,
    });
    clearErrors("locationofProvider");
  };

  const onHandleRegisterGoogle = () => {
    const body = {
      name: googleBody.name ? googleBody.name : googleBody.email,
      username: googleBody.email,
      picture: googleBody.picture,
      lat: geoLocation.lat.toString(),
      lng: geoLocation.long.toString(),
      role: "Seller",
      categories: servicesArrayModal,
    };
    registerGoogleUser(body, provider);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEAOhFgtiWHQMMBP1XUkxGjjWLpjqsnLQ",
    libraries: ["places"],
  });

  const onRemoveImage = (key) => {
    setImagePreview((currentState) => {
      return currentState.filter((image, index) => index != key);
    });
    setImages((currentState) => {
      return Array.from(currentState).filter((file, index) => index != key);
    });
  };

  const onHandleNext = (values) => {
    setScreen("Service");
  };

  const handleImageFileValidation = async (fileList) => {
    let imageURLs = [];
    if (fileList) {
      if (fileList.length >= 1 && fileList.length <= 3) {
        for (let i = 0; i < fileList.length; i++) {
          if (!IMAGE_FORMATS.includes(fileList[i]?.type)) {
            setImageError("Image should be jpeg, png or jpg");
            return;
          } else {
            imageURLs = [...imageURLs, URL.createObjectURL(fileList[i])];
          }
          setImagePreview(imageURLs);
          setImages(fileList);
          setImageError(null);
        }
      } else {
        setImageError("Min 1 and Max 3 Images required");
        return;
      }
    } else {
      setImageError("Images required");
      return;
    }
  };

  const AddService = () => {
    const [autocomplete, setAutoComplete] = useState(null);
    const [description, setDescription] = useState(null);
    const onHandleCKEditor = (data) => {
      setDescription(data);
    };

    const onLoad = (autocomplete) => {
      setAutoComplete(autocomplete);
    };
    const onPlaceChanged = () => {
      if (autocomplete !== null) {
        setValue("location", autocomplete.getPlace().formatted_address);
        setLat(autocomplete.getPlace().geometry.location.lat());
        setLng(autocomplete.getPlace().geometry.location.lng());
      } else {
        setErrorMessage("Locations is not loaded yet!");
      }
    };

    const onHandleRegister = (values) => {
      if (!images.length > 0 || imageError) {
        return setImageError("Images Required");
      } else {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("categoryId", values.category.value);
        for (const image of images) {
          formData.append("images", image);
        }
        formData.append("description", description);
        formData.append("lat", lat);
        formData.append("lng", lng);
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("username", values.email);
        formData.append("city", values.city.value);
        formData.append("location", values?.locationofProvider?.value);
        formData.append("country", 1);
        formData.append("state", 1);
        formData.append("password", values.password);
        formData.append("confirmPassword", values.confirmPassword);
        formData.append("cellNumber", values.cellNumber);
        formData.append("role", "Seller");
        registerUser(formData);
      }
    };

    return (
      <form>
        <h5>Add a service to start off!</h5>
        <div className="container add-service-form-container-reg">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "firstName" }
                type={ "text" }
                placeHolder={ t("Enter First Name") }
                label={ `${t("first_name")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "lastName" }
                type={ "text" }
                placeHolder={ t("Enter Last Name") }
                label={ `${t("last_name")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <FloatingInputField
                name={ "title" }
                type={ "text" }
                placeHolder={ "Enter Title" }
                label={ "Business Name *" }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ "Title is required" }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              { " " }
              <FloatingInputField
                name={ "cellNumber" }
                type={ "number" }
                placeHolder={ t("Enter Cell No.") }
                label={ `${t("Phone/Cell")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Number is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <FloatLabelSelectDropDown
                name={ "city" }
                placeHolder={ "" }
                control={ control }
                label={ `${t("City")}*` }
                rules={ { required: true } }
                options={ cities }
                customStyle={ customSelectStyles }
                errorMessage={ t("City is required") }
                errors={ errors }
                onChange={ onHandleSelectCity }
              />{ " " }
            </div>
            { locations.length > 0 && (
              <div className="col-lg-6 col-md-12">
                <FloatLabelSelectDropDown
                  name={ "locationofProvider" }
                  placeHolder={ "" }
                  control={ control }
                  label={ t("Location") }
                  rules={ { required: false } }
                  options={ locations }
                  customStyle={ customSelectStyles }
                  errorMessage={ t("Location is required") }
                  errors={ errors }
                  onChange={ onHandleSelectLocation }
                />{ " " }
              </div>
            ) }
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatLabelSelectDropDown
                name={ "category" }
                placeHolder={ "" }
                control={ control }
                label={ "Services Offered*" }
                rules={ { required: true } }
                options={ categories }
                customStyle={ customSelectStyles }
                errorMessage={ "Services required" }
                errors={ errors }
                onChange={ handleSelectCategory }
              />{ " " }
            </div>
            <div className="col-lg-6 col-md-12">
              { isLoaded && (
                <Autocomplete
                  onLoad={ onLoad }
                  onPlaceChanged={ onPlaceChanged }
                  restrictions={ {
                    country: ["ci"],
                  } }
                >
                  <FloatingInputField
                    name={ "location" }
                    type={ "text" }
                    placeHolder={ "Search posatal code" }
                    label={ "Location where you provide the service*" }
                    control={ control }
                    rules={ { required: true } }
                    errors={ errors }
                    errorMessage={ "Location is required" }
                  />
                </Autocomplete>
              ) }
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Label>Add a description</Form.Label>
              <CKEditor
                name={ "description" }
                control={ control }
                rules={ {} }
                config={ {
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "undo",
                    "redo",
                    "numberedList",
                    "bulletedList",
                  ],
                } }
                editor={ ClassicEditor }
                data=""
                onReady={ (editor) => {
                  // You can store the "editor" and use when it is needed.
                } }
                onChange={ (event, editor) => {
                  const data = editor.getData();
                  onHandleCKEditor(data);
                } }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                <Form.Label>
                  Add a profile picture of yourself or of your company so
                  customers will know exactly who they’ll be working with*
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  placeholder="Upload Images"
                  onChange={ (event) =>
                    handleImageFileValidation(event.target.files)
                  }
                />
                <Form.Text className="text-muted">
                  { imageError && imageError }
                </Form.Text>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            { imagePreview &&
              imagePreview.map((image, i) => {
                return (
                  <div key={ i } className="col-4">
                    <div className="image-container">
                      <img src={ image } />
                      <div className="remove">
                        <ImCross onClick={ () => onRemoveImage(i) } />
                      </div>
                    </div>
                  </div>
                );
              }) }
          </div>
          <div className="row">
            <div className="col">
              <Form.Check
                name={ "agreement" }
                type={ "checkbox" }
                id={ `default-${"checkbox"}` }
                label={
                  <div className="agreement">
                    { t("You agree to Wizafri") } <a>{ t("User Agreement") }</a>,{ " " }
                    <a>{ t("Privacy Policy") }</a> { t("and") }{ " " }
                    <a>{ t("Cookie Policy") }</a>
                  </div>
                }
                onChange={ onHandleAgreement }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <button
                className="login-btn"
                onClick={ () => setScreen("reg") }
                type="submit"
              >
                Back
              </button>
            </div>
            <div className="col-lg-6 col-md-12">
              <button
                className="login-btn"
                onClick={ handleSubmit(onHandleRegister) }
                type="submit"
                disabled={ isDisabled || locationError }
              >
                { isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("Agree to Join")
                ) }
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const AddServiceModal = () => {
    setScreen("serviceModal");
    const [autocompleteService, setAutoCompleteService] = useState(null);
    const [descriptionService, setDescriptionService] = useState(null);
    const onHandleCKEditor = (data) => {
      setDescriptionService(data);
    };

    const onLoadService = (autocompleteService) => {
      setAutoCompleteService(autocompleteService);
    };
    const onPlaceChanged = () => {
      if (autocompleteService !== null) {
        setValue(
          "locationService",
          autocompleteService.getPlace().formatted_address
        );
        setLat(autocompleteService.getPlace().geometry.location.lat());
        setLng(autocompleteService.getPlace().geometry.location.lng());
      } else {
        setErrorMessage("Locations is not loaded yet!");
      }
    };

    const onHandleGoogleRegister = (values) => {
      if (!images.length > 0 || imageError) {
        return setImageError("Images Required");
      } else {
        const formData = new FormData();
        formData.append("title", values.titleService);
        formData.append("categoryId", values.category.value);
        for (const image of images) {
          formData.append("images", image);
        }
        formData.append("description", descriptionService);
        formData.append("lat", lat);
        formData.append("lng", lng);
        formData.append("firstName", values.firstNameService);
        formData.append("lastName", values.lastNameService);
        formData.append("username", googleBody.email);
        formData.append("city", values.city.value);
        formData.append("location", values?.locationofProvider?.value);
        formData.append("country", 1);
        formData.append("state", 1);
        formData.append("cellNumber", values.cellNumberService);
        formData.append("role", "Seller");
        formData.append("picture", googleBody.picture);
        console.log(formData, "values");
        registerGoogleUser(formData, provider);
      }
    };

    return (
      <form>
        <h5>Add a service to start off!</h5>
        <div className="container add-service-form-container-reg">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "firstNameService" }
                type={ "text" }
                placeHolder={ t("Enter First Name") }
                label={ `${t("first_name")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "lastNameService" }
                type={ "text" }
                placeHolder={ "Enter Last Name" }
                label={ `${t("last_name")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <FloatingInputField
                name={ "titleService" }
                type={ "text" }
                placeHolder={ "Enter Title" }
                label={ "Title | Business Name *" }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ "Title is required" }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              { " " }
              <FloatingInputField
                name={ "cellNumberService" }
                type={ "number" }
                placeHolder={ t("Enter Cell No.") }
                label={ `${t("Phone/Cell")}*` }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Number is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <FloatLabelSelectDropDown
                name={ "city" }
                placeHolder={ "" }
                control={ control }
                label={ `${t("City")}*` }
                rules={ { required: true } }
                options={ cities }
                customStyle={ customSelectStyles }
                errorMessage={ t("City is required") }
                errors={ errors }
                onChange={ onHandleSelectCity }
              />{ " " }
            </div>
            { locations.length > 0 && (
              <div className="col-lg-6 col-md-12">
                <FloatLabelSelectDropDown
                  name={ "locationofProvider" }
                  placeHolder={ "" }
                  control={ control }
                  label={ "Location" }
                  rules={ { required: false } }
                  options={ locations }
                  customStyle={ customSelectStyles }
                  errorMessage={ "Location is required" }
                  errors={ errors }
                  onChange={ onHandleSelectLocation }
                />{ " " }
              </div>
            ) }
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatLabelSelectDropDown
                name={ "category" }
                placeHolder={ "" }
                control={ control }
                label={ "Services Offered*" }
                rules={ { required: true } }
                options={ categories }
                customStyle={ customSelectStyles }
                errorMessage={ "Services required" }
                errors={ errors }
                onChange={ handleSelectCategory }
              />{ " " }
            </div>
            <div className="col-lg-6 col-md-12">
              { isLoaded && (
                <Autocomplete
                  onLoad={ onLoadService }
                  onPlaceChanged={ onPlaceChanged }
                  restrictions={ {
                    country: ["ci"],
                  } }
                >
                  <FloatingInputField
                    name={ "locationServiceService" }
                    type={ "text" }
                    placeHolder={ "Search posatal code" }
                    label={ "Location where you provide the service*" }
                    control={ control }
                    rules={ { required: true } }
                    errors={ errors }
                    errorMessage={ "Location is required" }
                  />
                </Autocomplete>
              ) }
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Label>Add a description</Form.Label>
              <CKEditor
                name={ "descriptionService" }
                control={ control }
                rules={ {} }
                config={ {
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "undo",
                    "redo",
                    "numberedList",
                    "bulletedList",
                  ],
                } }
                editor={ ClassicEditor }
                data=""
                onReady={ (editor) => {
                  // You can store the "editor" and use when it is needed.
                } }
                onChange={ (event, editor) => {
                  const data = editor.getData();
                  onHandleCKEditor(data);
                } }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                <Form.Label>
                  Add a profile picture of yourself or of your company so
                  customers will know exactly who they’ll be working with*
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  placeholder="Upload Images"
                  onChange={ (event) =>
                    handleImageFileValidation(event.target.files)
                  }
                />
                <Form.Text className="text-muted">
                  { imageError && imageError }
                </Form.Text>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            { imagePreview &&
              imagePreview.map((image, i) => {
                return (
                  <div key={ i } className="col-4">
                    <div className="image-container">
                      <img src={ image } />
                      <div className="remove">
                        <ImCross onClick={ () => onRemoveImage(i) } />
                      </div>
                    </div>
                  </div>
                );
              }) }
          </div>
          <div className="row">
            <div className="col">
              <Form.Check
                name={ "agreement" }
                type={ "checkbox" }
                id={ `default-${"checkbox"}` }
                label={
                  <div className="agreement">
                    { t("You agree to Wizafri") } <a>{ t("User Agreement") }</a>,{ " " }
                    <a>{ t("Privacy Policy") }</a> { t("and") }{ " " }
                    <a>{ t("Cookie Policy") }</a>
                  </div>
                }
                onChange={ onHandleAgreementService }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <button
                className="login-btn"
                onClick={ handleSubmit(onHandleGoogleRegister) }
                type="submit"
                disabled={ isDisabled }
              >
                { isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("Agree to Join")
                ) }
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  useEffect(() => {
    getCountries();
    getCategories();
  }, []);

  return (
    <>
      <ErrorAlert message={ errorMessage } setErrorMessage={ setErrorMessage } />
      <CustomModal
        title={ "Success" }
        hide={ toggleModal }
        show={ modalVisible }
        backdrop="static"
      >
        <div className="d-flex flex-column align-items-center">
          <h1>
            <BsFillCheckCircleFill className="success" />
          </h1>
          <h5 className="mb-3 mt-3">
            { t("Congratulations! Your account has been created") }
          </h5>
          <button
            className="modal-btn"
            onClick={ () => navigate(`/login`) }
            type="submit"
          >
            { t("Login to Continue") }
          </button>
        </div>
      </CustomModal>
      <CustomModal
        title={ "Success" }
        hide={ categoriesModal }
        show={ catModalVisible }
        backdrop="static"
      >
        <div className="d-flex flex-column">
          <h5 className="mb-3 mt-3 align-items-center">
            { t("Congratulations! Your account has been created") }
          </h5>
          <AddServiceModal />
        </div>
      </CustomModal>

      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center ps-5 pe-5">
          <div className="account-content">
            <video
              className=""
              id="videoPlayer"
              controls
              muted="muted"
              width={ "100%" }
              height={ "100%" }
            >
              <source src={ videoUrl } type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="content">
            <div className="container">
              <>
                { " " }
                <div className="row">
                  <div className="col-md-12 ps-5 pe-5">
                    {/* Login Content */ }
                    <div className="account-content">
                      <div className="align-items-center justify-content-center">
                        <div className="login-right">
                          <div className="login-header text-center">
                            <Link to="/">
                              <img
                                src={ logo }
                                alt="logo"
                                className="simg-fluid"
                              />
                            </Link>
                            <h3>{ t("Join Wizafari as a Service Provider") }</h3>
                            <p>
                              Not a service provider?{ " " }
                              <a href="/register">Join as a user</a>
                            </p>
                          </div>
                          { screen === "reg" && (
                            <form>
                              <div className="row">
                                <div className="col">
                                  <FloatingInputField
                                    name={ "email" }
                                    type={ "text" }
                                    placeHolder={ t("Enter Email") }
                                    label={ `${t("Email or Username")}*` }
                                    control={ control }
                                    rules={ { required: true } }
                                    errors={ errors }
                                    errorMessage={ t("Email is required") }
                                  />{ " " }
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  { " " }
                                  <FloatingInputField
                                    name={ "password" }
                                    type={ "password" }
                                    placeHolder={ t("Enter Password") }
                                    label={ `${t("Password")}*` }
                                    control={ control }
                                    rules={ { required: true } }
                                    errors={ errors }
                                    errorMessage={ t("Password is required") }
                                  />{ " " }
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <FloatingInputField
                                    name={ "confirmPassword" }
                                    type={ "password" }
                                    placeHolder={ t("Confirm Password") }
                                    label={ `${t("Confirm Password")}*` }
                                    control={ control }
                                    rules={ {
                                      required: true,
                                      validate: (value) =>
                                        getValues("password") === value,
                                    } }
                                    errors={ errors }
                                    errorMessage={ t("Passwords must match") }
                                  />{ " " }
                                </div>
                              </div>{ " " }
                              <div className="row"></div>
                              <button
                                className="login-btn"
                                onClick={ handleSubmit(onHandleNext) }
                                type="button"
                              >
                                Next
                              </button>
                            </form>
                          ) }
                          { screen === "Service" && <AddService /> }
                        </div>
                      </div>
                    </div>
                    {/* /Login Content */ }
                  </div>
                </div>
                <div className="row mt-1 socials signup_socials">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    {/* <LoginSocialGoogle
                      client_id={ process.env.REACT_APP_GG_APP_ID || "" }
                      scope={ "email" }
                      // onLoginStart={onLoginStart}
                      onResolve={ ({ provider, data }) => {
                        setProvider(provider);
                        setGoogleBody(data);
                        categoriesModal(data);
                      } }
                      onReject={ (err) => {
                        console.log(err);
                      } }
                    > */}
                    <button className="social-login-google" type="button">
                      <i className="fa fa-google m-2"></i>
                      { isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        t("Signup With Google")
                      ) }
                    </button>
                    {/* </LoginSocialGoogle> */ }
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <LoginSocialFacebook
                      appId={ process.env.REACT_APP_FB_APP_ID || "" }
                      onResolve={ ({ provider, data }) => {
                        setProvider(provider);
                        setGoogleBody(data);
                        categoriesModal(data);
                      } }
                      onReject={ (err) => {
                        console.log(err);
                      } }
                    >
                      <button className="social-login-facebook" type="button">
                        <i className="fa fa-facebook m-2"></i>
                        { isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          t("Signup With Facebook")
                        ) }
                      </button>
                    </LoginSocialFacebook>
                  </div>

                  <div className="col-6 dont-have">
                    { t("Already on Wizafri?") }{ " " }
                    <Link to="/login">{ t("Click here") }</Link>
                  </div>
                </div>{ " " }
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JoinAsPro;
