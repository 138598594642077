import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Layout from "../../layouts/Layout";
import dummy from "../../assets/images/dummy.png";
import placeHolder from "../../assets/images/andriod.png";
import { getProviderDetailReq, getFollowReq } from "../../services/api";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loader";
import { useSelector } from "react-redux";
import ErrorAlert from "../../components/ErrorAlert";
import moment from "moment-timezone";
// import Cookies from "js-cookie";

const ProviderDetails = () => {
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [canFollow, setCanFollow] = useState(true);
  let { providerId } = useParams();
  const state = useSelector((state) => state);
  const { isAuthenticated } = state.auth;
  const { id } = state.auth.user;
  // let recentlyViewed = Cookies.get("recentlyViewed")
  //   ? JSON.parse(Cookies.get("recentlyViewed"))
  //   : [];

  const getFollow = async () => {
    setIsLoading(true);
    try {
      await getFollowReq(id, providerId);
      getServiceDetail();
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getServiceDetail = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedDetails } = await getProviderDetailReq(providerId);
      setDetails(fetchedDetails.data);
      if (fetchedDetails?.data?.followers) {
        const found = fetchedDetails?.data?.followers?.find((follower) => {
          return Number(follower) === id;
        });

        if (Number(found) === id) {
          setCanFollow(false);
        } else {
          setCanFollow(true);
        }
      } else {
        setCanFollow(true);
      }
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   const index = recentlyViewed.findIndex((id) => {
  //     return id === providerId;
  //   });
  //   if (index === -1) {
  //     recentlyViewed = [...recentlyViewed, providerId];
  //     Cookies.set("recentlyViewed", JSON.stringify(recentlyViewed));
  //   }
  // }, []);

  useEffect(() => {
    getServiceDetail();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
      <Layout>
        <div className="provider_details_container">
          <div className="content">
            <div className="container profile_container">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile">
                    <div className="profile-box">
                      <div className="provider-widget">
                        <div className="pro-info-left">
                          <div className="provider-img">
                            <img
                              src={
                                details?.profilePic
                                  ? details?.profilePic
                                  : dummy
                              }
                              alt="User"
                            />
                          </div>
                          <div className="profile-info">
                            <h2 className="profile-title">{details?.name}</h2>
                            <p className="profile-position">
                              {details?.userCategories[0]?.category.title}
                            </p>
                            {/* <div>
                              <a href="#" className="btn full-btn">
                                Full time
                              </a>
                            </div> */}
                            {/* <ul className="profile-preword">
                              <li>
                                <img src={""} alt="" height={16} /> Poland
                              </li>
                              <li>
                                <div className="rating">
                                  <span className="average-rating">4.6</span>
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star" />
                                </div>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                        <div className="pro-info-right profile-inf r-0">
                          {/* <ul className="profile-right">
                            <li>
                              <div>
                                <h3 className="amt-hr">
                                  ${details?.hourly_rate}
                                </h3>{" "}
                                <p>( Per Hour )</p>
                              </div>
                            </li>
                          </ul> */}
                          {/* <div className="d-flex align-items-center justify-content-md-end justify-content-center">
                            <a href="">
                              <i className="fas fa-heart heart fa-2x me-2 orange-text" />
                            </a>
                            <a
                              className="btn bid-btn"
                              data-bs-toggle="modal"
                              href="#hire"
                            >
                              Hire Now{" "}
                              <i className="fas fa-long-arrow-alt-right" />
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="pro-view">
                    {/* Tab Detail */}
                    {/* /Tab Detail */}
                    {/* Overview Tab Content */}
                    <div
                      className="pro-post widget-box pe-3 ps-4 pt-1"
                      id="overview"
                    >
                      <h3 className="pro-title">Overview</h3>
                      <div
                        className="pro-content"
                        dangerouslySetInnerHTML={{
                          __html: details?.overview,
                        }}
                      ></div>
                    </div>
                    {/* /Overview Tab Content */}
                    {/* Project Tab Content */}
                    {/* <div
                      className="pro-post project-widget widget-box pe-3 ps-4 pt-1"
                      id="project"
                    >
                      <h3 className="pro-title">Portfolio</h3>
                      <div className="pro-content">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="project-widget">
                              <div className="pro-image">
                                <a href={""} data-fancybox="gallery2">
                                  <img
                                    className="img-fluid"
                                    alt="User Image"
                                    src={placeHolder}
                                  />
                                </a>
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">Project name</h3>
                                <p className="pro-designation">Web design</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <Link to="" className="btn more-btn">
                              View more{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="pro-post author-widget clearfix pe-3 ps-4 pt-1"
                      id="feedback"
                    >
                      <div className="widget-title-box clearfix">
                        <h3 className="pro-title mb-0">Feedbacks</h3>
                      </div>
                      {details &&
                      details.userReviews &&
                      details.userReviews.length > 0
                        ? details.userReviews.map((review) => (
                            <div className="about-author">
                              <div className="about-author-img">
                                <div className="author-img-wrap">
                                  <img
                                    className="img-fluid"
                                    alt=""
                                    src={
                                      review.reviewBy.profilePic
                                        ? review.reviewBy?.profilePic
                                        : dummy
                                    }
                                  />
                                </div>
                              </div>
                              <div className="author-details">
                                {/* <a href="#" className="blog-author-name">
                            Logo Designer
                          </a> */}
                                <h5>{moment(review.createdAt).fromNow()}</h5>
                                <div className="rating">
                                  <span className="average-rating">
                                    {review.review}
                                  </span>
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star" />
                                </div>
                                <p className="mb-0">{review.comments}</p>
                              </div>
                            </div>
                          ))
                        : "No reviews"}
                    </div>
                    {/* /Feedback Tab Content */}
                  </div>
                </div>
                {/* Blog Sidebar */}
                <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    {/* Follow Widget */}
                    <div className="pro-post widget-box follow-widget p-2 pb-4">
                      {isAuthenticated && id !== Number(providerId) && (
                        <button
                          className="follow-btn"
                          onClick={() => getFollow()}
                        >
                          {canFollow ? "+ Follow" : "Unfollow"}
                        </button>
                      )}
                      <ul className="follow-posts pro-post">
                        <li>
                          <p>Following</p>
                          <h6>
                            {details?.follow ? details?.follow.length : 0}
                          </h6>
                        </li>
                        <li>
                          <p>Followers</p>
                          <h6>
                            {details?.followers ? details?.followers.length : 0}
                          </h6>
                        </li>
                      </ul>
                    </div>

                    <div className="pro-post widget-box about-widget p-2">
                      <h4 className="pro-title mb-0">About Me</h4>
                      <ul className="latest-posts pro-content">
                        {/* <li>
                          <p>Gender</p>
                          <h6>Male</h6>
                        </li>
                        <li>
                          <p>Experience</p>
                          <h6>5 Years</h6>
                        </li> */}
                        <li>
                          <p>Location</p>
                          <h6>
                            {details?.city?.title}/{details?.country?.title}
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </StickyBox>
                </div>
                {/* /Blog Sidebar */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProviderDetails;
