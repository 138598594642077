import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { getWishListReq } from "../../services/api";
import { useSelector } from "react-redux";
import Loading from "../../components/Loader.jsx";
import ErrorAlert from "../../components/ErrorAlert";
import Service from "../../components/Service";

const WishList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [wishList, setWishList] = useState([]);
  const state = useSelector((state) => state);
  const { id } = state.auth.user;

  const getWishlist = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedwishList } = await getWishListReq(id);
      setWishList(fetchedwishList.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getWishlist();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Layout>
        <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
        <div
          className="container"
          style={{ marginTop: "90px", marginBottom: "15px" }}
        >
          <h2>My Wishlist</h2>
          <div className="row">
            {wishList.length > 0 &&
              wishList.map((list) => {
                return (
                  <div className="col-3">
                    <Service data={list} />
                  </div>
                );
              })}
          </div>
        </div>
      </Layout>
    </>
  );
};
export default WishList;
