import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { getSellerReviews } from "../../services/api";
import { Spinner } from "react-bootstrap";

function Index(props) {
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reviews, setSellerReviews] = useState([]);

  const getSellerReviewsData = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getSellerReviews(id);
      setSellerReviews(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getSellerReviewsData(id);
  }, []);
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="pro-title without-border">Reviews</h3>
                    </div>
                    <div className="card-body">
                      <div className="reviews">
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : reviews.length > 0 ? (
                          reviews.map((review) => (
                            <div className="review-content no-padding">
                              <h4>{review?.jobReview?.title}</h4>
                              <div className="rating">
                                <span className="average-rating">
                                  {review?.review}
                                </span>
                                <>
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star filled" />
                                  <i className="fas fa-star" />
                                </>

                                {/* {
                          <>
                          review?.review = 5 ? (
                            <><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star" /></>
                          ) :
                          review?.review = 4 ? (
                            <><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star filled" /></>
                          )
                          :
                          review?.review = 3 ? (
                            <><i className="fas fa-star filled" /><i className="fas fa-star filled" /><i className="fas fa-star filled" /></>
                          )
                          :
                          review?.review = 2 ? (
                            <><i className="fas fa-star filled" /><i className="fas fa-star filled" /></>
                          )
                          :
                          review?.review = 1 ? (
                            <><i className="fas fa-star filled" /></>
                          )
                          </>
                      } */}
                              </div>
                              <p className="mb-0">{review?.comments}</p>
                            </div>
                          ))
                        ) : (
                          "No reviews"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Index;
