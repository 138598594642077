import React, { useState, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import { Button, Form, Spinner } from "react-bootstrap";
import FloatingInputField from "../FloatingInputField";
import { useForm } from "react-hook-form";
import { changeJobStatusReq } from "../../services/api/seller";
import { changeJobStatusReqBuyer, submitReviews } from "../../services/api";
import { useSelector } from "react-redux";
import StarRating from "../../components/StarRating";
// import "node_modules/react-star-rating/dist/css/react-star-rating.min.css"
const BuyerRequests = (props) => {
  const sellerJobs = props?.data;
  const state = useSelector((state) => state);
  const { id, role } = state.auth.user;
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [CancelJobAlert, setCancelJobAlert] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [afterConfirm, setafterConfirm] = useState(false);
  const [reviewJobId, setReviewJobId] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [sellerId, setSellerId] = useState(null);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const toggleModal = (jobId, status) => {
    setJobStatus(status);
    setJobId(jobId);
    setCancelJobAlert(true);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { biddingAmount: "" } });

  const changeJobStatus = async (jobId, status) => {
    setIsLoading(true);
    try {
      role === "Seller"
        ? await changeJobStatusReq(id, jobId, status)
        : await changeJobStatusReqBuyer(id, jobId, status);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const cancelRejectProsal = () => {
    setJobStatus(null);
    setJobId(null);
    setCancelJobAlert(false);
  };
  const onConfirmCancelJob = async () => {
    setIsLoading(true);
    try {
      setCancelJobAlert(false);
      setafterConfirm(true);
      await changeJobStatus(jobId, jobStatus);
      navigate(0);
    } catch (err) {
      setErrorMessage(err.response || err.response.data.message);
    }
    setIsLoading(false);
  };

  const reviewCustomModal = (jobId, sellerId) => {
    setReviewJobId(jobId);
    setSellerId(sellerId);
    setReviewModal(true);
  };
  const cancelreviewCustomModal = () => {
    setReviewJobId(null);
    setSellerId(null);
    setReviewModal(false);
  };

  const handleReview = async (value) => {
    setIsLoading(true);
    try {
      let body = {
        rating: rating,
        comments: value.comments,
        sellerId: sellerId,
      };
      await submitReviews(id, reviewJobId, body);
      cancelreviewCustomModal();
      navigate(0);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <CustomModal
        title={"Success"}
        hide={reviewCustomModal}
        show={reviewModal}
      >
        <div className="modal-header">
          <h4 className="modal-title">Please leave your feedback</h4>
          <span className="modal-close">
            <a onClick={cancelreviewCustomModal} aria-label="Close">
              <i className="fa fa-times-circle" />
            </a>
          </span>
        </div>
        <Form>
          <div className="m-2 center">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={index <= (hover || rating) ? "on" : "off"}
                      onClick={() => setRating(index)}
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(rating)}
                    >
                      <span className="star">
                        <i
                          className="fa fa-star"
                          style={{ fontSize: "36px" }}
                        />
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <FloatingInputField
            name={"comments"}
            type={"textarea"}
            placeHolder={"Notes"}
            label={"Leave your comments"}
            control={control}
            rules={{ required: true }}
            errors={errors}
            errorMessage={"Please leave your comments"}
          />
          <Button onClick={handleSubmit(handleReview)} type="submit">
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Send Message"
            )}
          </Button>
        </Form>
      </CustomModal>
      {CancelJobAlert && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="Light"
          cancelBtnBsStyle="primary"
          // title="Do you realy want to reject"
          onConfirm={onConfirmCancelJob}
          onCancel={cancelRejectProsal}
        >
          Are you sure
        </SweetAlert>
      )}
      <div className="mb-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 flex-wrap">
            <div className="table-responsive table-box">
              <table className="table table-center table-hover datatable">
                <thead className="thead-pink">
                  <tr>
                    <th>JobID</th>
                    <th>Client</th>
                    <th>Amount</th>
                    <th>status</th>
                    <th>Assign Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerJobs.map((job, index) => (
                    <tr key={index + 1}>
                      <td>{job.id}</td>
                      <td className="mb-0">{job.createdBy?.name}</td>
                      <td>${job.price}</td>
                      <td>{job.status}</td>
                      {job.status === "Completed" && (
                        <td>
                          {new Date(job.completedAt).toLocaleDateString()}
                        </td>
                      )}

                      {job.status === "In Progress" && role === "Seller" && (
                        <>
                          <td>
                            <Button
                              className="btn btn-sm btn-success mr-10"
                              onClick={() => toggleModal(job.id, "Delivered")}
                            >
                              {console.log(role)}
                              Deliver
                            </Button>

                            <Button
                              className="btn btn-sm btn-danger"
                              onClick={() => toggleModal(job.id, "Cancelled")}
                            >
                              Cancel Order
                            </Button>
                          </td>
                        </>
                      )}
                      {job.status === "Delivered" && role === "Seller" && (
                        <>
                          <td>
                            <Button className="btn btn-sm btn-success">
                              Delivered
                            </Button>
                          </td>
                        </>
                      )}
                      {job.status === "Delivered" && role === "Buyer" && (
                        <>
                          <td>
                            <Button
                              className="btn btn-sm btn-success mr-10"
                              onClick={() => toggleModal(job.id, "Completed")}
                            >
                              {console.log(role)}
                              Mark As Complete
                            </Button>

                            <Button
                              className="btn btn-sm btn-danger"
                              onClick={() => toggleModal(job.id, "Cancelled")}
                            >
                              Cancel Order
                            </Button>
                          </td>
                        </>
                      )}
                      {job.status === "In Progress" && role === "Buyer" && (
                        <>
                          <td>
                            <Button className="btn btn-sm btn-default">
                              In Progress
                            </Button>
                            <Button
                              className="btn btn-sm btn-danger"
                              onClick={() => toggleModal(job.id, "Cancelled")}
                            >
                              Cancel Order
                            </Button>
                          </td>
                        </>
                      )}
                      {job.status === "Completed" &&
                        role === "Buyer" &&
                        !job.is_rating && (
                          <td>
                            <Button
                              className="btn btn-sm btn-success"
                              onClick={() =>
                                reviewCustomModal(job.id, job.fk_user_id)
                              }
                            >
                              Review
                            </Button>
                          </td>
                        )}
                      {job.status === "Completed" && role === "Seller" && (
                        <td>
                          <Button className="btn btn-sm btn-success">
                            Completed
                          </Button>
                        </td>
                      )}
                      {job.status === "Completed" &&
                        role === "Buyer" &&
                        job.is_rating > 0 && (
                          <td>
                            <Button className="btn btn-sm btn-success">
                              Completed
                            </Button>
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerRequests;
