import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    username: null,
    cellNumber: null,
    city: null,
    state: null,
    country: null,
    name: null,
    role: null,
    lat: null,
    lng: null,
  },
  accessToken: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = true;
    },
    updateProfile: (state, action) => {
      state.user = action.payload.user;
    },
    logout: () => {
      return initialState;
    },
  },
});

export const { login, logout, updateProfile } = authSlice.actions;

export default authSlice;
