import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../assets/images/moda_img.png";
import apple from "../assets/images/apple.jpeg";
import playstore from "../assets/images/playstore.jpeg";

const MobileViewModal = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [lgShow, setLgShow] = useState(true);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        userAgent
      )
    );
  }, []);

  const handleClose = () => {
    localStorage.setItem("popupClosed", "true");
  };

  if (!isMobile || localStorage.getItem("popupClosed")) {
    return null;
  }

  return (
    <>
      {isMobile && (
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            closeButton
            onClick={handleClose}
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div
                  className="col-xs-12 text-center"
                  style={{ padding: "0px" }}
                >
                  <img src={logo} style={{ height: "auto", width: "320px" }} />
                  <h2 className="heading-1 mt-4">
                    The No. 1 service market place in Africa
                  </h2>
                  <h6>Download the App</h6>
                  <div className="flex">
                    <a
                      href="#"
                      className="col-xs-6"
                      style={{ padding: "10px" }}
                    >
                      <img
                        src={apple}
                        className="iphone-btn"
                        style={{ height: "auto", width: "130px" }}
                      />
                    </a>
                    <a
                      href="#"
                      className="col-xs-6"
                      style={{ padding: "10px" }}
                    >
                      <img
                        src={playstore}
                        className="playstore-btn"
                        style={{ height: "auto", width: "130px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default MobileViewModal;
