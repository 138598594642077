import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import logo from "../../assets/images/dummy.png";
import { logout } from "../../store/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserSkillsReq, addUserSkillsReq } from "../../services/api";
import ErrorAlert from "../../components/ErrorAlert";
import { Spinner } from "react-bootstrap";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    Navigate(`/login`);
  };

  const [userSkills, setUserSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const state = useSelector((state) => state);
  const { id, name } = state.auth.user;
  const [disableAdd, setDisableAdd] = useState(true);
  const [skillValue, setSkillValue] = useState(null);

  const getUserSkills = async () => {
    setIsLoading(true);
    try {
      const res = await getUserSkillsReq(id);
      setUserSkills(res.data.data.skills);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const addUserSkills = async (body) => {
    setIsLoading(true);
    try {
      await addUserSkillsReq(id, body);
      await getUserSkills();
      setSkillValue("");
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const pathname = window.location.pathname;

  const handleOnaddSkills = (event) => {
    if (event.target.value !== "") {
      setDisableAdd(false);
      setSkillValue(event.target.value);
    } else {
      setDisableAdd(true);
    }
  };

  const addSkills = () => {
    if (skillValue) {
      const body = {
        skillName: skillValue,
      };
      addUserSkills(body);
    }
  };

  useEffect(() => {
    getUserSkills();
  }, []);
  return (
    <>
      <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
      <div className="settings-widget">
        <div className="settings-header d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
          <Link to="freelancer-profile">
            <img
              alt="profile image"
              src={logo}
              className="avatar-lg rounded-circle"
            />
          </Link>
          <div className="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
            <h3 className="mb-0">
              <Link to="/">{name}</Link>
            </h3>
          </div>
        </div>
        <div className="settings-menu">
          <ul>
            <li className="nav-item">
              <Link
                to="/seller/dashboard"
                className={`nav-link ${
                  pathname === "/seller/dashboard" ? "active" : ""
                }`}
              >
                <i className="material-icons">verified_user</i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/services"
                className={`nav-link ${
                  pathname === "/seller/services" ? "active" : ""
                }`}
              >
                <i className="material-icons">verified_user</i> All Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/addService"
                className={`nav-link ${
                  pathname === "dashboard" ? "active" : ""
                }`}
              >
                <i className="material-icons">add</i> Add Service
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/jobs-requests"
                className={`nav-link ${
                  pathname === "/seller/jobs-requests" ? "active" : ""
                }`}
              >
                <i className="material-icons">record_voice_over</i>Requests
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/jobs"
                className={`nav-link ${
                  pathname === "/seller/jobs" ? "active" : ""
                }`}
              >
                <i className="material-icons">business_center</i> Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/reviews"
                className={`nav-link ${
                  pathname === "/seller/reviews" ? "active" : ""
                }`}
              >
                <i className="material-icons">record_voice_over</i> Reviews
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/chats"
                className={`nav-link ${
                  pathname === "/seller/chats" ? "active" : ""
                }`}
              >
                <i className="material-icons">chat</i> Messages
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/seller/profile"
                className={`nav-link ${pathname === "#" ? "active" : ""}`}
              >
                <i className="material-icons">settings</i> Profile Settings
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={handleLogout} className="nav-link">
                <i className="material-icons">power_settings_new</i> Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="filter-widget skill-div">
        <h5>My Skills</h5>
        <div className="form-group mb-2">
          {userSkills.length > 0
            ? userSkills.map((skill) => {
                return (
                  <span className="badge-pill badge-skill bage-color mb-1 me-1">
                    {skill.title}
                  </span>
                );
              })
            : "No skills added yet"}
        </div>
        <div className="input_div">
          <input
            value={skillValue}
            type="text"
            className="form-control"
            onChange={handleOnaddSkills}
          />{" "}
          {!isLoading ? (
            <button onClick={addSkills} disabled={disableAdd}>
              +
            </button>
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        </div>
      </div> */}
    </>
  );
};
export default Sidebar;
