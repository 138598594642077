import React, { useState, useEffect } from "react";
import FloatingInputField from "../../components/FloatingInputField";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { useForm } from "react-hook-form";
import { IoIosAddCircle } from "react-icons/io";
import { Form, Spinner } from "react-bootstrap";
import FloatLabelSelectDropDown from "../../components/FloatingSelectInput";
import { customSelectStyles, IMAGE_FORMATS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  getCitiesReq,
  getCountriesReq,
  updateProfileReq,
} from "../../services/api";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { logout, updateProfile } from "../../store/features/authSlice";
import ErrorAlert from "../../components/ErrorAlert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { isEmpty } from "lodash";

const Profile = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const {
    id,
    name,
    username,
    cell_number,
    fk_country_id,
    fk_city_id,
    hourly_rate,
  } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [countryFetched, setCountryFetched] = useState(false);
  const [cityFetched, setCityFetched] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [overview, setOverview] = useState(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCountries } = await getCountriesReq();
      const countries = fetchedCountries.data.map((country) => {
        return {
          label: country.title,
          value: country.id,
        };
      });
      setCountryFetched(true);
      setCountries(countries);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const onHandleCKEditor = (data) => {
    if (isEmpty(data)) {
      setOverview(null);
    } else {
      setOverview(data);
    }
  };

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    try {
      await changePassword(id, values);
      dispatch(logout());
      navigate(`/login`);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleUpdateProfile = async (values) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("name", values.name);
      formData.append("cellNumber", values.cellNumber);
      formData.append("country", values.country.value);
      formData.append("city", values.city.value);
      formData.append("hourlyRate", values.hourlyRate);
      formData.append("overview", overview);
      if (images) {
        formData.append("image", images[0]);
      }
      const { data: user } = await updateProfileReq(id, formData);
      dispatch(updateProfile(user.data));
      navigate(`/seller/dashboard`);
      setImages(null);
      setImagePreview(null);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleSelectCountry = (values) => {
    setValue("country", {
      label: values.label,
      value: values.value,
    });
    clearErrors("country");
  };

  const handleSelectCity = (values) => {
    setValue("city", {
      label: values.label,
      value: values.value,
    });
    clearErrors("city");
  };

  const onRemoveImage = (key) => {
    setImagePreview((currentState) => {
      return currentState.filter((image, index) => index != key);
    });
    setImages((currentState) => {
      return Array.from(currentState).filter((file, index) => index != key);
    });
  };

  const getDefaultCountry = () => {
    return countries[
      countries.findIndex((cat) => cat.value === Number(fk_country_id))
    ];
  };
  const getDefaultCity = () => {
    return cities[cities.findIndex((cat) => cat.value === Number(fk_city_id))];
  };

  const getCities = async (countryId, stateId) => {
    setIsLoading(true);
    console.log(countryId, stateId);
    try {
      const { data: fetchedCities } = await getCitiesReq(countryId, stateId);
      const cities = fetchedCities.data.map((city) => {
        return {
          label: city.title,
          value: city.id,
        };
      });
      setCityFetched(true);
      setCities(cities);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleImageFileValidation = async (fileList) => {
    let imageURLs = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        if (!IMAGE_FORMATS.includes(fileList[i]?.type)) {
          setImageError("Image should be jpeg, png or jpg");
          return;
        } else {
          imageURLs = [...imageURLs, URL.createObjectURL(fileList[i])];
        }
        setImagePreview(imageURLs);
        setImages(fileList);
        setImageError(null);
      }
    } else {
      setImageError("Images required");
      return;
    }
  };

  useEffect(() => {
    const country = getDefaultCountry();
    const city = getDefaultCity();
    setValue("country", country);
    setValue("city", city);
  }, [countryFetched, cityFetched]);

  useEffect(() => {
    getCountries();
    if (fk_country_id) {
      getCities(fk_country_id, 1);
    }
    setValue("email", username);
    setValue("name", name);
    setValue("cellNumber", cell_number);
    setValue("hourlyRate", hourly_rate);
  }, []);

  return (
    <>
      <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
      <Layout>
        <div className="content-dashboard">
          <div className="container-fluid m-2 p-3">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-md-8">
                <h2 className="mb-3">Profile Settings</h2>
                <div className="container add-service-form-container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"email"}
                        type={"text"}
                        placeHolder={"Enter Email"}
                        isDisabled={true}
                        label={"Email"}
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        errorMessage={"Email is required"}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"name"}
                        type={"text"}
                        placeHolder={"Enter Name"}
                        label={"Name"}
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        errorMessage={"Name is required"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <FloatLabelSelectDropDown
                        name={"country"}
                        placeHolder={""}
                        control={control}
                        label={"Country"}
                        rules={{ required: true }}
                        options={countries}
                        customStyle={customSelectStyles}
                        errorMessage={"Country is required"}
                        errors={errors}
                        onChange={handleSelectCountry}
                      />{" "}
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <FloatLabelSelectDropDown
                        name={"city"}
                        placeHolder={""}
                        control={control}
                        label={"City"}
                        rules={{ required: true }}
                        options={cities}
                        customStyle={customSelectStyles}
                        errorMessage={"City is required"}
                        errors={errors}
                        onChange={handleSelectCity}
                      />{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"cellNumber"}
                        type={"text"}
                        placeHolder={"Enter Cell Number"}
                        label={"Cell Number"}
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        errorMessage={"Cell Number is required"}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"hourlyRate"}
                        type={"number"}
                        placeHolder={"Enter hourly Rate"}
                        label={"Hourly Rate"}
                        control={control}
                        rules={{ required: true }}
                        errors={errors}
                        errorMessage={"Hourly Rate required"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <CKEditor
                        name={"overview"}
                        control={control}
                        rules={{ required: true }}
                        config={{
                          toolbar: [
                            "heading",
                            "bold",
                            "italic",
                            "undo",
                            "redo",
                            "numberedList",
                            "bulletedList",
                          ],
                        }}
                        editor={ClassicEditor}
                        data=""
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onHandleCKEditor(data);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Upload Profile Photo</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Upload Profile Photo"
                          onChange={(event) =>
                            handleImageFileValidation(event.target.files)
                          }
                        />
                        <Form.Text className="text-muted">
                          {imageError && imageError}
                        </Form.Text>
                      </Form.Group>{" "}
                    </div>
                  </div>
                  <div className="row">
                    {imagePreview &&
                      imagePreview.map((image, i) => {
                        return (
                          <div key={i} className="col-4">
                            <div className="image-container">
                              <img src={image} />
                              <div className="remove">
                                <ImCross onClick={() => onRemoveImage(i)} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="d-flex justify-content-end mt-2 mb-3">
                    <button
                      className="w-25 login-btn"
                      onClick={handleSubmit(handleUpdateProfile)}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <>Update Profile</>
                      )}
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"password"}
                        type={"text"}
                        placeHolder={"Enter Password"}
                        label={"Password"}
                        control={control}
                        rules={{}}
                        errors={errors}
                        errorMessage={"Email is required"}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <FloatingInputField
                        name={"confirmPassword"}
                        type={"text"}
                        placeHolder={"Confirm Password"}
                        label={"Confirm Password"}
                        control={control}
                        rules={{}}
                        errors={errors}
                        errorMessage={"Name is required"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 mb-3">
                    <button
                      className="w-25 login-btn"
                      onClick={handleSubmit(handleChangePassword)}
                    >
                      {isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <>Change Password</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
