import axios from "axios";

export const getBuyerRequest = (userId, status) => {
  return axios.get(`/users/${userId}/buyerRequests/seller`);
};

export const submitProposal = (userId, buyerRequestId, data) => {
  return axios.post(
    `/users/${userId}/buyerRequests/${buyerRequestId}/proposals/seller`,
    data
  );
};
export const submittedProposals = (userId) => {
  return axios.get(`/users/${userId}/proposals/seller`);
};

export const getSellerJobsReq = (userId, status) => {
  return axios.get(`/users/${userId}/jobs/seller`, { params: { status } });
};

export const changeJobStatusReq = (userId, jobId, status) => {
  return axios.get(`/users/${userId}/jobs/${jobId}/changeStatus/seller`, {
    params: { status },
  });
};
