import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import fr from "./locale/fr.json";

const language = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      debug: true,
      resources: {
        en: {
          translation: en,
        },
        fr: {
          translation: fr,
        },
      },
      lng: "fr", // if you're using a language detector, do not define the lng option
      fallbackLng: "fr",

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
  const checkLang = localStorage.getItem("lang");
  let currentLang = "";
  if (!checkLang) {
    localStorage.setItem("lang", i18n.language);
  }
  currentLang = localStorage.getItem("lang");
  i18n.changeLanguage(currentLang);
};
export default language;
