import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCountriesReq, getCitiesReq } from "../../services/api";
import { Label } from "../../constants";
import Select, { components } from "react-select";
import { customSelectStyles } from "../../constants";
import { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Cookies from "js-cookie";

export const Control = (props) => {
  return (
    <>
      <Label
        isFloating={props.isFocused || props.hasValue}
        className="d-flex justify-content-center align-items-center zIndex1"
      >
        {props.selectProps["aria-label"]}
      </Label>
      <components.Control {...props} />
    </>
  );
};

const SelectLocation = ({ selectedCategory, hideModal }) => {
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let searches = Cookies.get("searches")
    ? JSON.parse(Cookies.get("searches"))
    : [];

  const navigate = useNavigate();

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCountries } = await getCountriesReq();
      const countries = fetchedCountries.data.map((country) => {
        return {
          label: country.title,
          value: country.id,
        };
      });
      await getCities(countries[0]?.value, 1);
      setSelectedCountry(countries[0]);
      setCountries(countries);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCities = async (countryId, stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedCities } = await getCitiesReq(countryId, stateId);
      const cities = fetchedCities.data.map((city) => {
        return {
          label: city.title,
          value: city.id,
        };
      });
      setCities(cities);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const handleSelectCountry = (values) => {
    getCities(1, values.value);
  };

  const onHandleSelectCity = (values) => {
    setSelectedCity(values);
  };

  const handleSkip = () => {
    searches = [
      ...searches,
      {
        title: selectedCategory.label,
        url: `/search?category=${selectedCategory.label}&categoryId=${selectedCategory.value}`,
      },
    ];
    Cookies.set("searches", JSON.stringify(searches));
    hideModal && hideModal();
    navigate(
      `/search?category=${selectedCategory.label}&categoryId=${selectedCategory.value}`
    );
    navigate(0);
  };

  const handleSearch = () => {
    searches = [
      ...searches,
      {
        title: selectedCategory.label,
        url: `/search?category=${selectedCategory.label}&categoryId=${
          selectedCategory.value
        }${
          selectedCountry
            ? `&country=${selectedCountry.label}&countryId=${selectedCountry.value}`
            : ""
        }${
          selectedCity
            ? `&city=${selectedCity.label}&cityId=${selectedCity.value}`
            : ""
        }`,
      },
    ];
    Cookies.set("searches", JSON.stringify(searches));
    hideModal && hideModal();
    navigate(
      `/search?category=${selectedCategory.label}&categoryId=${
        selectedCategory.value
      }${
        selectedCountry
          ? `&country=${selectedCountry.label}&countryId=${selectedCountry.value}`
          : ""
      }${
        selectedCity
          ? `&city=${selectedCity.label}&cityId=${selectedCity.value}`
          : ""
      }`
    );
    navigate(0);
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="d-flex flex-column p-2 select-location">
      <h5 className="text-center">Where do you need the service?</h5>
      {/* <Select
        components={{
          Control,
        }}
        options={countries}
        styles={customSelectStyles}
        maxMenuHeight={100}
        placeholder={null}
        className={`tg-select-react mb-3`}
        classNamePrefix="tg-select"
        aria-label={"Select a country"}
        onChange={handleSelectCountry}
      /> */}
      <Select
        components={{
          Control,
        }}
        options={cities}
        styles={customSelectStyles}
        placeholder={null}
        // menuPlacement={"top"}
        className={`tg-select-react mb-3`}
        classNamePrefix="tg-select"
        aria-label={"Select a city"}
        maxMenuHeight={100}
        onChange={onHandleSelectCity}
      />
      <div className="d-flex justify-content-between align-items-center ps-1 pe-1">
        <button
          className="w-50 btn btn-primary sub-btn"
          onClick={handleSearch}
          type="submit"
        >
          Search
        </button>
        <a onClick={handleSkip}>
          Skip <AiOutlineArrowRight />
        </a>
      </div>
    </div>
  );
};

export default SelectLocation;
