import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import ApexCharts from "apexcharts";
import nodejs from "../../assets/images/nodejs.png";
import { getSellerDashboard } from "../../services/api";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

const Dashboard = () => {
  const state = useSelector((state) => state);
  const { id } = state.auth.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  var chartprofileoptions = {
    series: [
      {
        name: "profile view",
        data: [100, 150, 200, 250, 200, 250, 200, 200, 200, 200, 300, 350],
      },
    ],
    chart: {
      height: 360,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF5B37"],
    stroke: {
      curve: "straight",
      width: [1],
    },
    markers: {
      size: 4,
      colors: ["#FF5B37"],
      strokeColors: "#FF5B37",
      strokeWidth: 1,
      hover: {
        size: 7,
      },
    },
    grid: {
      position: "front",
      borderColor: "#ddd",
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  };

  var chartradialOptions = {
    series: [
      dashboardData?.appliedJobs,
      dashboardData?.inProgressJobs,
      dashboardData?.completedJobs,
    ],
    chart: {
      toolbar: {
        show: false,
      },
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ["#7B46BE", "#FA6CA4", "#FACD3A", "#24C0DC"],
    labels: ["Applied Jobs", "In Progress", "Completed"],
    legend: {
      show: false,
      floating: true,
      fontSize: "16px",
      position: "bottom",
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  const getSellerDashboardData = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getSellerDashboard(id);
      setDashboardData(data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getSellerDashboardData(id);
  }, []);
  return (
    <div>
      <Layout>
        <>
          <section className="content-dashboard">
            {/* Page Content */}
            <div className="container-fluid m-2 p-3">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  <Sidebar />
                </div>
                <div className="col-md-12 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="dash-widget">
                        <div className="dash-info">
                          <div className="dash-widget-info">Applied Jobs</div>
                          <div className="dash-widget-count">
                            {isLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              dashboardData?.appliedJobs
                            )}
                          </div>
                        </div>
                        <div className="dash-widget-more">
                          <Link to="/seller/jobs-requests" className="d-flex">
                            View Details{" "}
                            <i className="fas fa-arrow-right ms-auto" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="dash-widget">
                        <div className="dash-info">
                          <div className="dash-widget-info">In Progress</div>
                          <div className="dash-widget-count">
                            {isLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              dashboardData?.inProgressJobs
                            )}
                          </div>
                        </div>
                        <div className="dash-widget-more">
                          <Link to="/seller/jobs" className="d-flex">
                            View Details{" "}
                            <i className="fas fa-arrow-right ms-auto" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="dash-widget">
                        <div className="dash-info">
                          <div className="dash-widget-info">Completed Jobs</div>
                          <div className="dash-widget-count">
                            {isLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              dashboardData?.completedJobs
                            )}
                          </div>
                        </div>
                        <div className="dash-widget-more">
                          <Link to="/seller/jobs" className="d-flex">
                            View Details{" "}
                            <i className="fas fa-arrow-right ms-auto" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-xl-8 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-title">Profile Views</h5>
                            {/* <div className="dropdown">
                              <button
                                className="btn btn-white btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Monthly
                              </button>
                              <div className="dropdown-menu dropdown-menu-start">
                                <a href="" className="dropdown-item">
                                  Weekly
                                </a>
                                <a href="" className="dropdown-item">
                                  Monthly
                                </a>
                                <a href="" className="dropdown-item">
                                  Yearly
                                </a>
                              </div>
                            </div> 
                          </div>
                        </div>
                        <div className="card-body">
                          <Chart
                            options={chartprofileoptions}
                            series={chartprofileoptions.series}
                            type="line"
                            width="650"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-xl-4 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-title">Static Analytics</h5>
                            {/* <div className="dropdown">
                              <button
                                className="btn btn-white btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Monthly
                              </button>
                              <div className="dropdown-menu dropdown-menu-start">
                                <a href="" className="dropdown-item">
                                  Weekly
                                </a>
                                <a href="" className="dropdown-item">
                                  Monthly
                                </a>
                                <a href="" className="dropdown-item">
                                  Yearly
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="card-body">
                          {/* <div id="chartradial" /> */}
                          <Chart
                            options={chartradialOptions}
                            series={chartradialOptions.series}
                            type="radialBar"
                            width="300"
                          />
                          <ul className="static-list">
                            <li>
                              <span>
                                <i className="fas fa-circle text-violet me-1" />{" "}
                                Applied Jobs
                              </span>{" "}
                              <span className="sta-count">
                                {isLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  dashboardData?.appliedJobs
                                )}
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="fas fa-circle text-pink me-1" />{" "}
                                In Progress
                              </span>{" "}
                              <span className="sta-count">
                                {isLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  dashboardData?.inProgressJobs
                                )}
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="fas fa-circle text-yellow me-1" />{" "}
                                Completed
                              </span>{" "}
                              <span className="sta-count">
                                {isLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  dashboardData?.completedJobs
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Content */}
          </section>
        </>
      </Layout>
    </div>
  );
};

export default Dashboard;
