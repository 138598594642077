import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout.jsx";
import hero from "../../assets/images/banner.png";
import moileAppBannerEn from "../../assets/images/wizafri_mobile_app_en.png";
import moileAppBannerFr from "../../assets/images/wizafri_mobile_app_fr.png";
import plumbing from "../../assets/images/plumbing.png";
import air_condition from "../../assets/images/air_condition.png";
import electricity from "../../assets/images/electricity.png";
import gardening from "../../assets/images/gardening.png";
import paint from "../../assets/images/paint.png";
import placeHolder from "../../assets/images/andriod.png";
import construction from "../../assets/images/construction.png";
import { Link } from "react-router-dom";
import service_provider from "../../assets/images/service_provider.jpg";
import { useTranslation } from "react-i18next";
import { Form, FloatingLabel } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "../../components/Carousel/ButtonGroup";
import { useNavigate } from "react-router-dom";
import { Label } from "../../constants";
import Select, { components } from "react-select";
import { customSelectStyles } from "../../constants";
import Loading from "../../components/Loader.jsx";
import {
  getCategoriesListReq,
  getFeaturedCategoriesListReq,
  getRecommendedPros,
  getTopRatedSerives,
  getTopRatedPros,
  getRecentlyViewedReq,
} from "../../services/api";
import CustomModal from "../../components/CustomModal";
import ErrorAlert from "../../components/ErrorAlert.jsx";
import SelectLocation from "./SelectLocation.jsx";
import Cookies from "js-cookie";
import Service from "../../components/Service.jsx";
import Provider from "../../components/Provider.jsx";
import { useSelector } from "react-redux";

export const Control = (props) => {
  return (
    <>
      <Label
        isFloating={props.isFocused || props.hasValue}
        className="d-flex justify-content-center align-items-center zIndex1"
      >
        {props.selectProps["aria-label"]}
      </Label>
      <components.Control {...props} />
    </>
  );
};

function Index(props) {
  const [categories, setCategories] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [recommendedPros, setRecommendedPros] = useState([]);
  const [topRatedServices, setTopRatedServices] = useState([]);
  const [topRatedPros, setTopRatedPros] = useState([]);
  const [recent, setRecent] = useState([]);
  const state = useSelector((state) => state);
  const { isAuthenticated } = state.auth;
  const { id } = state.auth.user;
  const lang = localStorage.getItem("lang");
  const mobileBanner = lang == "en" ? moileAppBannerEn : moileAppBannerFr;

  const recentlyViewed = Cookies.get("recentlyViewed")
    ? JSON.parse(Cookies.get("recentlyViewed"))
    : [];

  let searches = Cookies.get("searches")
    ? JSON.parse(Cookies.get("searches"))
    : [];

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleSelectCategory = (value) => {
    value ? setSelectedCategory(value) : setSelectedCategory(null);
  };

  const getRecentlyViewed = async (body) => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getRecentlyViewedReq(id, body);
      setRecent(fetchedCategories.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getCategoriesListReq();
      setCategories(
        fetchedCategories.data.map((category) => {
          return {
            label: category.title,
            value: category.id,
          };
        })
      );
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getFeaturedCategories = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCategories } = await getFeaturedCategoriesListReq();
      setFeaturedCategories(fetchedCategories.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getRecPros = async () => {
    setIsLoading(true);
    try {
      const { data } = await getRecommendedPros();
      setRecommendedPros(data.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getTopRatedServices = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTopRatedSerives();

      setTopRatedServices(data.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getTopRatedProsServices = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTopRatedPros();
      setTopRatedPros(data.data);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigateCategory = (category) => {
    searches = [
      ...searches,
      {
        title: category.title,
        url: `/search?category=${category.title}&categoryId=${category.id}`,
      },
    ];
    Cookies.set("searches", JSON.stringify(searches));
    navigate(`/search?category=${category.title}&categoryId=${category.id}`);
  };
  useEffect(() => {
    getCategories();
    getFeaturedCategories();
    getRecPros();
    getTopRatedServices();
    getTopRatedProsServices();
    getRecentlyViewed({ recentServiceIds: recentlyViewed });
  }, []);

  return (
    <div>
      {isLoading && <Loading />}
      <Layout>
        <ErrorAlert message={errorMessage} setErrorMessage={setErrorMessage} />
        <CustomModal title={"Success"} hide={toggleModal} show={modalVisible}>
          <SelectLocation selectedCategory={selectedCategory} />
        </CustomModal>{" "}
        <section className="section home-banner row-middle">
          <div className="container-fluid px-5">
            <div className="row">
              <div className="col-md-5 col-lg-6">
                <div className="banner-content">
                  <h1>
                    <span className="green-text">{t("title")}</span>
                  </h1>
                  <p>{t("slider_subtitle")}</p>
                  <div className="w-md-100 home_search_bar">
                    <Select
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable={true}
                      options={categories}
                      styles={customSelectStyles}
                      placeholder={null}
                      className={`tg-select-react`}
                      classNamePrefix="tg-select"
                      aria-label={"What service do you need?"}
                      onChange={handleSelectCategory}
                    />
                    <button
                      className="btn btn-primary sub-btn"
                      onClick={toggleModal}
                      type="submit"
                      disabled={!selectedCategory}
                    >
                      {t("Search")}
                    </button>
                  </div>
                  {searches.length > 0 && (
                    <small>
                      Recently searched:{" "}
                      {searches.reverse().map((search, key) => {
                        if (key < 3) {
                          return (
                            <a key={key} href={search.url}>
                              {search.title}{" "}
                            </a>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </small>
                  )}
                  <div className="mt-3">
                    <Carousel
                      partialVisible={false}
                      additionalTransfrom={0}
                      arrows={true}
                      autoPlaySpeed={4000}
                      autoPlay={false}
                      centerMode={false}
                      className="gd-carousel popular_categories"
                      containerClass="container-padding-bottom"
                      customButtonGroup=""
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite={false}
                      itemClass="carouselItem"
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside={true}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024,
                          },
                          items: 6,
                          partialVisibilityGutter: 40,
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0,
                          },
                          items: 4,
                          partialVisibilityGutter: 30,
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464,
                          },
                          items: 2,
                          partialVisibilityGutter: 30,
                        },
                      }}
                      rewind={true}
                      rewindWithAnimation={true}
                      rtl={false}
                      shouldResetAutoplay={true}
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable={false}
                    >
                      {/* <small className="popular_categories"> */}
                      {featuredCategories.map((category, key) => {
                        return (
                          <a
                            key={key}
                            onClick={() => {
                              handleNavigateCategory(category);
                            }}
                          >
                            <span className="popular_icon">
                              <img src={category.image} />
                              <small>{category.title}</small>
                            </span>
                          </a>
                        );
                      })}
                      {/* </small> */}
                    </Carousel>
                    {/* </small> */}
                  </div>
                </div>
                <div className="app-img">
                  <img
                    src={mobileBanner}
                    className="img-fluid"
                    alt="Mobile Application Banner"
                  />
                </div>
              </div>
              <div className="col-md-4 col-lg-6">
                <div className="banner-img">
                  <img src={hero} className="img-fluid" alt="banner" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {recent.length > 0 && (
          <section className="container-fluid custom-slider mb-10">
            <div style={{ position: "relative", padding: "16px 32px 32px" }}>
              <h4>Recently Viewed</h4>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                autoPlay
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                customButtonGroup={<ButtonGroup />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 4.5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={true}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay={true}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable={false}
              >
                {recent.map((service) => (
                  <Service data={service} />
                ))}
              </Carousel>
            </div>
          </section>
        )}
        {recommendedPros.length > 0 && (
          <section className="container-fluid custom-slider mb-10">
            <div style={{ position: "relative", padding: "16px 32px 32px" }}>
              <h4>Recommended Pros</h4>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                autoPlay
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                customButtonGroup={<ButtonGroup />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 4.5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={true}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay={true}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable={false}
              >
                {recommendedPros.map((service) => (
                  <Service data={service} />
                ))}
              </Carousel>
            </div>
          </section>
        )}
        {topRatedServices.length > 0 && (
          <section className="container-fluid custom-slider mb-10">
            <div style={{ position: "relative", padding: "16px 32px 32px" }}>
              <h4>Top Rated Services</h4>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                autoPlay
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                customButtonGroup={<ButtonGroup />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 4.5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={true}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay={true}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable={false}
              >
                {topRatedServices.map((service) => (
                  <Service data={service} />
                ))}
              </Carousel>
            </div>
          </section>
        )}
        {topRatedPros.length > 0 && (
          <section className="container-fluid custom-slider mb-10">
            <div style={{ position: "relative", padding: "16px 32px 32px" }}>
              <h4>Top Rated Pros</h4>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                autoPlay
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                customButtonGroup={<ButtonGroup />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 4.5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={true}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay={true}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable={false}
              >
                {topRatedPros.map((service) => (
                  <Service data={service} />
                ))}
              </Carousel>
            </div>
          </section>
        )}
        <section className="mt-2  section work mb-3">
          <div className="container-fluid">
            <div className="row">
              {/* Feature Item */}
              <div className="col-md-6 work-box bg1">
                <div className="work-content">
                  <h2>
                    {t("WIZAFRI is very easy to use in")}{" "}
                    <span>{t("3 steps")}</span>
                  </h2>
                  <p>
                    <ol>
                      <li>{t("Search the list of professionals")}</li>
                      <li>{t("Compare the offer of each professional")}</li>
                      <li>{t("Choose the professional that suits you")}</li>
                    </ol>
                  </p>
                  <h5 style={{ width: "400px" }}>
                    {t("Find Pros in your area by clicking below!")}
                  </h5>
                  <Link to="/search">
                    <i className="fa fa-long-arrow-right long-arrow" />
                  </Link>
                </div>
              </div>
              {/* /Feature Item */}
              <div className="col-md-6 work-box bg2">
                <div className="work-content">
                  <h2>
                    {t("Wizafri for any project and")}
                    <span> {t("for any profession")}</span>
                  </h2>
                  <p>
                    {t(
                      "Get in touch with potential professionals for your project and interact with them to select the best service and solution offer for your project."
                    )}
                  </p>
                  <h5>{t("Register Now!")}</h5>
                  <Link to="/register">
                    <i className="fa fa-long-arrow-right long-arrow" />
                  </Link>
                </div>
                {/* /Feature Item */}
              </div>
            </div>
          </div>
        </section>
        <section className="section agency mb-3">
          <div className="container-fluid">
            <div className="row align-items-center black-bg">
              <div className="col-md-6 agency-box">
                <img src={service_provider} alt="" className="img-fluid" />
              </div>
              <div className="col-md-6 agency-box black-bg">
                <div className="agency-content">
                  <h2>{t("Professional section heading")}</h2>
                  <p>{t("Professional section")}</p>
                  <Link to="/joinAsPro" className="link">
                    {t("Professional section link")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default Index;
