import React from "react";
import { Link, Navigate } from "react-router-dom";
import logo from "../../assets/images/dummy.png";
import { logout } from "../../store/features/authSlice";
import { useDispatch } from "react-redux";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    Navigate(`/login`);
  };
  const pathname = window.location.pathname;
  return (
    <>
      <div className="settings-widget">
        <div className="settings-header d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
          <Link to="freelancer-profile">
            <img
              alt="profile image"
              src={logo}
              className="avatar-lg rounded-circle"
            />
          </Link>
          <div className="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
            <h3 className="mb-0">
              <Link to="/">Nasir Ullah</Link>
            </h3>
          </div>
        </div>
        <div className="settings-menu">
          <ul>
            <li className="nav-item">
              <Link
                to="/buyer/dashboard"
                className={`nav-link ${
                  pathname === "/buyer/dashboard" ? "active" : ""
                }`}
              >
                <i className="material-icons">verified_user</i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/buyer/jobs"
                className={`nav-link ${
                  pathname === "/buyer/jobs" ? "active" : ""
                }`}
              >
                <i className="material-icons">business_center</i> All Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/buyer/advertiseJob"
                className={`nav-link ${
                  pathname === "/buyer/advertiseJob" ? "active" : ""
                }`}
              >
                <i className="material-icons">add</i> Post a Request
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/buyer/jobs-requests"
                className={`nav-link ${
                  pathname === "/buyer/jobs-requests" ? "active" : ""
                }`}
              >
                <i className="material-icons">record_voice_over</i> Jobs
                Requests
              </Link>
            </li> */}

            <li className="nav-item">
              <Link
                to="/buyer/all-jobs-requests"
                className={`nav-link ${
                  pathname === "/buyer/all-jobs-requests" ? "active" : ""
                }`}
              >
                <i className="material-icons">record_voice_over</i> All Requests
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/buyer/chats"
                className={`nav-link ${
                  pathname === "/buyer/chats" ? "active" : ""
                }`}
              >
                <i className="material-icons">chat</i> Messages
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="#"
                className={`nav-link ${pathname === "#" ? "active" : ""}`}
              >
                <i className="material-icons">settings</i> Settings
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={handleLogout} className="nav-link">
                <i className="material-icons">power_settings_new</i> Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
