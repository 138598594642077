import axios from "axios";

export const getCountriesReq = () => {
  return axios.get(`/common/countries`);
};

export const getRecommendedPros = () => {
  return axios.get(`/services/getRecommended`);
};
export const getTopRatedSerives = () => {
  return axios.get(`/services/getTopRatedSerives`);
};
export const getTopRatedPros = () => {
  return axios.get(`/services/getTopRatedPros`);
};

export const getStatesReq = (countryId) => {
  return axios.get(`/common/states/${countryId}`);
};

export const getCitiesReq = (countryId, stateId) => {
  return axios.get(`/common/cities/${countryId}/${stateId}`);
};

export const getLocationsReq = (cityId) => {
  return axios.get(`/common/locations/${cityId}`);
};

export const registerUserReq = (body) => {
  return axios.post(`/users/registration`, body);
};
export const registerUserSocial = (body, provider) => {
  return axios.post(`/users/socialRegistration?provide=${provider}`, body);
};

export const loginReq = (body) => {
  return axios.post(`/users/login`, body);
};
export const loginSocialReq = (body) => {
  return axios.post(`/users/socialLogin`, body);
};

export const getCategoriesReq = (userId) => {
  return axios.get(`/users/${userId}/categories/getAll`);
};

export const getCategoriesListReq = () => {
  return axios.get(`/categories/list`);
};
export const getFeaturedCategoriesListReq = () => {
  return axios.get(`/categories`);
};

export const getUserSkillsReq = (userId) => {
  return axios.get(`/users/${userId}/skills`);
};

export const addUserSkillsReq = (userId, body) => {
  return axios.post(`/users/${userId}/skills`, body);
};

export const addServicesReq = (userId, body) => {
  return axios.post(`/users/${userId}/services`, body);
};

export const getSellerServices = (userId) => {
  return axios.get(`/users/${userId}/services`);
};

export const advertiseBuyerRequest = (userId, body) => {
  return axios.post(`/users/${userId}/buyerRequests`, body);
};
export const getBuyerRequest = (userId, status) => {
  return axios.get(`/users/${userId}/buyerRequests?status=${status}`);
};

export const rejectProjectReq = (userId, requestId) => {
  return axios.get(`/users/${userId}/buyerRequests/${requestId}/cancel`);
};
export const getProposalsReq = (userId, id, sort) => {
  return axios.get(
    `/users/${userId}/buyerRequests/${id}/proposals?sort=${sort || ""}`
  );
};

export const sendMessage = (userId, body) => {
  return axios.post(`/users/${userId}/rooms`, body);
};

export const chatListsReq = (userId) => {
  return axios.get(`/users/${userId}/rooms`);
};
export const chatRoomMessages = (userId, roomId) => {
  return axios.get(`/users/${userId}/rooms/${roomId}`);
};

export const sendRoomMessage = (userId, roomId, body) => {
  return axios.post(`/users/${userId}/rooms/${roomId}/messages`, body);
};

export const awardJobReq = (userId, body) => {
  return axios.post(`/users/${userId}/jobs/awardJob`, body);
};
export const rejectProposalReq = (userId, requestId, proposalId) => {
  return axios.get(
    `/users/${userId}/buyerRequests/${requestId}/proposals/${proposalId}/reject`
  );
};
export const getBuyerJobsReq = (userId, status) => {
  return axios.get(`/users/${userId}/jobs/buyer`, { params: { status } });
};

export const changeJobStatusReqBuyer = (userId, jobId, status) => {
  return axios.get(`/users/${userId}/jobs/${jobId}/changeStatus/buyer`, {
    params: { status },
  });
};

export const submitReviews = (userId, jobId, body) => {
  return axios.post(`/users/${userId}/jobs/${jobId}/submitReview`, body);
};

export const updateProfileReq = (userId, body) => {
  return axios.put(`/users/${userId}`, body);
};
export const changePassword = (userId, body) => {
  return axios.put(`/users/${userId}/changePassword`, body);
};

export const searchReq = (
  categoryId,
  countryId,
  cityId,
  locationId,
  limit,
  page
) => {
  return axios.get(
    `/search?categoryId=${categoryId ? categoryId : ""}&countryId=${
      countryId ? countryId : ""
    }&cityId=${cityId ? cityId : ""}&locationId=${
      locationId ? locationId : ""
    }&limit=${limit}&page=${page}`
  );
};

export const getServiceDetailReq = (serviceId) => {
  return axios.get(`/services/${serviceId}/detail`);
};

export const getProviderDetailReq = (providerId) => {
  return axios.get(`/users/${providerId}/profile`);
};

export const getFollowReq = (userId, followId) => {
  return axios.get(`/users/${userId}/follow/${followId}`);
};

export const getSellerDashboard = (userId) => {
  return axios.get(`/users/${userId}/dashboard/seller`);
};

export const getSellerReviews = (userId) => {
  return axios.get(`/users/${userId}/reviews/seller`);
};

export const createWishList = (userId, serviceId) => {
  return axios.get(`/users/${userId}/services/${serviceId}/addToWishList`);
};

export const getWishListReq = (userId) => {
  return axios.get(`/users/${userId}/buyer/services/wishlisted`);
};

export const getRecentlyViewedReq = (userId, body) => {
  return axios.post(`/services/recentViews`, body);
};

// common apis

export const getAboutUsReq = () => {
  return axios.get(`/common/about-us`);
};
export const getTermsAndConditionReq = () => {
  return axios.get(`/common/terms-and-condtions`);
};

export const getPrivacyAndPolicyReq = () => {
  return axios.get(`/common/privacy-and-policay`);
};
