import React from "react";
import Sidebar from "../../layouts/dashboard/Layout";
import Layout from "../../layouts/Layout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Jobs from "../../components/jobs/jobs";

function Index(props) {
  const { t } = useTranslation();
  return (
    <div>
      <Layout>
        <>
          <div className="content-dashboard">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="page-title">
                    <h3>Manage Requests/Proposals</h3>
                  </div>
                  <Tabs
                    defaultActiveKey="Jobs requests"
                    className="nav nav-tabs nav-tabs-bottom nav-justified"
                    id="controlled-tabs"
                    selectedTabClassName="bg-white"
                  >
                    <Tab
                      className="nav-item"
                      eventKey="Jobs requests"
                      title="Requests"
                    >
                      <Jobs />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="Proposal Submitted"
                      title="Proposal Submitted"
                    >
                      <Jobs />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="Withdraw / Close Jobs"
                      title="Withdraw / Close Jobs"
                    >
                      <Jobs />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Index;
