import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import FloatingInputField from "../../components/FloatingInputField";
import CustomModal from "../../components/CustomModal";
import { useForm } from "react-hook-form";
import FloatLabelSelectDropDown from "../../components/FloatingSelectInput";
import { customSelectStyles } from "../../constants";
import ErrorAlert from "../../components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/features/authSlice";

import {
  getCountriesReq,
  getCitiesReq,
  registerUserReq,
  registerUserSocial,
  loginSocialReq,
  getLocationsReq,
} from "../../services/api";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

const Register = () => {
  const { t, i18n } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      cellNumber: "",
    },
  });

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [secondScreenVisible, setSecondScreenVisible] = useState(false);
  const [secondScreenVisibleSocial, setSecondScreenVisibleSocial] =
    useState(false);

  const [googleBody, setGoogleBody] = useState(null);
  const [provider, setProvider] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleSecondScreen = () => {
    setSecondScreenVisible(!secondScreenVisible);
  };
  const toggleSecondScreenSocial = () => {
    setSecondScreenVisibleSocial(!secondScreenVisibleSocial);
  };
  const videoUrl = `${process.env.REACT_APP_BASE_URL}/video?lang=${i18n.language}`

  const registerUser = async (body) => {
    setIsLoading(true);
    try {
      await registerUserReq(body);
      toggleModal();
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedCountries } = await getCountriesReq();
      const countries = fetchedCountries.data.map((country) => {
        return {
          label: country.title,
          value: country.id,
        };
      });
      getCities(countries[0].value, 1);
      setValue("country", {
        label: countries[0].title,
        value: countries[0].value,
      });
      setSelectedCountryId(countries[0].value);
      setCountries(countries);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const getCities = async (countryId, stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedCities } = await getCitiesReq(countryId, stateId);
      const cities = fetchedCities.data.map((city) => {
        return {
          label: city.title,
          value: city.id,
        };
      });
      setCities(cities);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const getLocations = async (stateId) => {
    setIsLoading(true);
    try {
      const { data: fetchedLocations } = await getLocationsReq(stateId);
      const locations = fetchedLocations.data.map((location) => {
        return {
          label: location.title,
          value: location.id,
        };
      });
      setLocations(locations);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };
  const onHandleAgreement = (event) => {
    event.target.checked ? setIsDisabled(false) : setIsDisabled(true);
  };
  const onHandleAgreementSocial = (event) => {
    event.target.checked ? setIsDisabled(false) : setIsDisabled(true);
  };
  const handleSelectCountry = (values) => {
    setSelectedCountryId(values.value);
    getCities(values.value, 1);
    setValue("country", {
      label: values.label,
      value: values.value,
    });
    clearErrors("country");
  };

  const onHandleSelectState = (values) => {
    getCities(selectedCountryId, values.value);
    setValue("state", {
      label: values.label,
      value: values.value,
    });
    clearErrors("state");
  };

  const onHandleSelectCity = (values) => {
    setValue("city", {
      label: values.label,
      value: values.value,
    });
    getLocations(values.value);
    clearErrors("city");
  };

  const onHandleSelectLocation = (values) => {
    setValue("location", {
      label: values.label,
      value: values.value,
    });
    clearErrors("location");
  };

  const onHandleRegister = (values) => {
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      country: values.country.value,
      city: values.city.value,
      state: 1,
      cellNumber: values.cellNumber,
      location: values?.location?.value,
      role: "Buyer",
    };
    registerUser(body);
  };

  const socialLogin = async (data) => {
    const user = await loginSocialReq({
      username: data.username || data.email,
    });
    if (user && user.data && user.data.success) {
      dispatch(login(user.data.data));
      navigate(`/buyer/dashboard`);
      return true;
    } else {
      return false;
    }
  };
  const regSocialUser = async (body) => {
    setIsLoading(true);
    try {
      const loginUser = await socialLogin(body);
      if (!loginUser) {
        const reqbody = {
          firstName: body.firstName,
          lastName: body.lastName,
          username: googleBody.email,
          country: body.country.value,
          city: body.city.value,
          state: 1,
          cellNumber: body.cellNumber,
          location: body?.location?.value,
          role: "Buyer",
        };
        // registerUser(body);
        // const reqbody = {
        //   name: body.name ? body.name : body.email,
        //   username: body.email,
        //   picture: body.picture,
        //   lat: "1234",
        //   lng: "1234",
        //   role: "Buyer",
        // };

        const { data } = await registerUserSocial(reqbody, provider);
        console.log(data);
        if (data.success) {
          await socialLogin(reqbody);
        }
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setLocationError(false);
    getCountries();
  }, []);

  return (
    <>
      <ErrorAlert message={ errorMessage } setErrorMessage={ setErrorMessage } />
      <CustomModal
        title={ "Add City" }
        hide={ toggleSecondScreenSocial }
        show={ secondScreenVisibleSocial }
        backdrop="static"
        size="lg"
      >
        <div className="container p-2">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "firstName" }
                type={ "text" }
                placeHolder={ "Enter First Name" }
                label={ t("first_name") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "lastName" }
                type={ "text" }
                placeHolder={ "Enter Last Name" }
                label={ t("last_name") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            {/* <div className="col-lg-6 col-md-12">
                              {" "}
                              <FloatingInputField
                                name={"cellNumber"}
                                type={"number"}
                                placeHolder={t("Enter Cell No.")}
                                label={t("Phone/Cell")}
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                errorMessage={t("Number is required")}
                              />
                            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatLabelSelectDropDown
                name={ "city" }
                placeHolder={ "" }
                control={ control }
                label={ t("City") }
                rules={ { required: true } }
                options={ cities }
                customStyle={ customSelectStyles }
                errorMessage={ t("City is required") }
                errors={ errors }
                onChange={ onHandleSelectCity }
              />{ " " }
            </div>
            { locations.length > 0 && (
              <div className="col-lg-6 col-md-12">
                <FloatLabelSelectDropDown
                  name={ "location" }
                  placeHolder={ "" }
                  control={ control }
                  label={ t("Location") }
                  rules={ { required: false } }
                  options={ locations }
                  customStyle={ customSelectStyles }
                  errorMessage={ t("Location is required") }
                  errors={ errors }
                  onChange={ onHandleSelectLocation }
                />{ " " }
              </div>
            ) }
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              { " " }
              <FloatingInputField
                name={ "cellNumber" }
                type={ "number" }
                placeHolder={ t("Enter Cell No.") }
                label={ t("Phone/Cell") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Number is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Check
                name={ "agreement" }
                type={ "checkbox" }
                id={ `default-${"checkbox"}` }
                label={
                  <div className="agreement">
                    { t("You agree to Wizafri") } <a>{ t("User Agreement") }</a>,{ " " }
                    <a>{ t("Privacy Policy") }</a> { t("and") }{ " " }
                    <a>{ t("Cookie Policy") }</a>
                  </div>
                }
                onChange={ onHandleAgreement }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                className="login-btn"
                onClick={ handleSubmit(regSocialUser) }
                type="submit"
                disabled={ isDisabled || locationError }
              >
                { isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("Agree to Join")
                ) }
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-1 socials">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
          <div className="col-6 dont-have">
            { t("Already on Wizafri?") }{ " " }
            <Link to="/login">{ t("Click here") }</Link>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        title={ "Add City" }
        hide={ toggleSecondScreen }
        show={ secondScreenVisible }
        backdrop="static"
        size="lg"
      >
        <div className="container p-2">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "firstName" }
                type={ "text" }
                placeHolder={ t("Enter First Name") }
                label={ t("first_name") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <FloatingInputField
                name={ "lastName" }
                type={ "text" }
                placeHolder={ "Enter Last Name" }
                label={ t("last_name") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Name is required") }
              />
            </div>
            {/* <div className="col-lg-6 col-md-12">
                              {" "}
                              <FloatingInputField
                                name={"cellNumber"}
                                type={"number"}
                                placeHolder={t("Enter Cell No.")}
                                label={t("Phone/Cell")}
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                errorMessage={t("Number is required")}
                              />
                            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FloatLabelSelectDropDown
                name={ "city" }
                placeHolder={ "" }
                control={ control }
                label={ t("City") }
                rules={ { required: true } }
                options={ cities }
                customStyle={ customSelectStyles }
                errorMessage={ t("City is required") }
                errors={ errors }
                onChange={ onHandleSelectCity }
              />{ " " }
            </div>
            { locations.length > 0 && (
              <div className="col-lg-6 col-md-12">
                <FloatLabelSelectDropDown
                  name={ "location" }
                  placeHolder={ "" }
                  control={ control }
                  label={ t("Location") }
                  rules={ { required: false } }
                  options={ locations }
                  customStyle={ customSelectStyles }
                  errorMessage={ t("Location is required") }
                  errors={ errors }
                  onChange={ onHandleSelectLocation }
                />{ " " }
              </div>
            ) }
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              { " " }
              <FloatingInputField
                name={ "cellNumber" }
                type={ "number" }
                placeHolder={ t("Enter Cell No.") }
                label={ t("Phone/Cell") }
                control={ control }
                rules={ { required: true } }
                errors={ errors }
                errorMessage={ t("Number is required") }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Check
                name={ "agreement" }
                type={ "checkbox" }
                id={ `default-${"checkbox"}` }
                label={
                  <div className="agreement">
                    { t("You agree to Wizafri") } <a>{ t("User Agreement") }</a>,{ " " }
                    <a>{ t("Privacy Policy") }</a> { t("and") }{ " " }
                    <a>{ t("Cookie Policy") }</a>
                  </div>
                }
                onChange={ onHandleAgreement }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                className="login-btn"
                onClick={ () => setSecondScreenVisible(false) }
                type="button"
              >
                { isLoading ? <Spinner animation="border" size="sm" /> : "Back" }
              </button>
            </div>
            <div className="col-6">
              <button
                className="login-btn"
                onClick={ handleSubmit(onHandleRegister) }
                type="submit"
                disabled={ isDisabled || locationError }
              >
                { isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  t("Agree to Join")
                ) }
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-1 socials">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
          <div className="col-6 dont-have">
            { t("Already on Wizafri?") }{ " " }
            <Link to="/login">{ t("Click here") }</Link>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        title={ "Success" }
        hide={ toggleModal }
        show={ modalVisible }
        backdrop="static"
      >
        <div className="d-flex flex-column align-items-center">
          <h1>
            <BsFillCheckCircleFill className="success" />
          </h1>
          <h5 className="mb-3 mt-3">
            { t("Congratulations! Your account has been created") }
          </h5>
          <button
            className="modal-btn"
            onClick={ () => navigate(`/login`) }
            type="submit"
          >
            { t("Login to Continue") }
          </button>
        </div>
      </CustomModal>
      <div className="row">
        <div className="col-lg-6  d-flex flex-column justify-content-center ps-5 pe-5">
          <div className="account-content ">
            <video
              className=""
              id="videoPlayer"
              controls
              muted="muted"
              width={ "100%" }
              height={ "100%" }
            >
              <source src={ videoUrl } type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12 ps-5 pe-5">
                  {/* Login Content */ }
                  <div className="account-content">
                    <div className="align-items-center justify-content-center">
                      <div className="login-right">
                        <div className="login-header text-center">
                          <Link to="/">
                            <img src={ logo } alt="logo" className="img-fluid" />
                          </Link>
                          <h3>{ t("Join Wizafri") }</h3>
                          <p>
                            Are you a service provider?{ " " }
                            <a href="/joinAsPro">Join as a pro</a>
                          </p>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col">
                              <FloatingInputField
                                name={ "email" }
                                type={ "text" }
                                placeHolder={ t("Enter Email") }
                                label={ t("Email or Username") }
                                control={ control }
                                rules={ {
                                  required: secondScreenVisible && true,
                                } }
                                errors={ errors }
                                errorMessage={ t("Email is required") }
                              />{ " " }
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              { " " }
                              <FloatingInputField
                                name={ "password" }
                                type={ "password" }
                                placeHolder={ t("Enter Password") }
                                label={ t("Password") }
                                control={ control }
                                rules={ {
                                  required: secondScreenVisible && true,
                                } }
                                errors={ errors }
                                errorMessage={ t("Password is required") }
                              />{ " " }
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <FloatingInputField
                                name={ "confirmPassword" }
                                type={ "password" }
                                placeHolder={ t("Confirm Password") }
                                label={ t("Confirm Password") }
                                control={ control }
                                rules={ {
                                  required: secondScreenVisible && true,
                                  validate: (value) =>
                                    getValues("password") === value,
                                } }
                                errors={ errors }
                                errorMessage={ t("Passwords must match") }
                              />{ " " }
                            </div>
                          </div>{ " " }
                          <div className="row">
                            {/* <div className="col-lg-6 col-md-12">
                              <FloatLabelSelectDropDown
                                name={"city"}
                                placeHolder={""}
                                control={control}
                                label={t("City")}
                                rules={{ required: true }}
                                options={cities}
                                customStyle={customSelectStyles}
                                errorMessage={t("City is required")}
                                errors={errors}
                                onChange={onHandleSelectCity}
                              />{" "}
                            </div> */}
                          </div>
                          <button
                            className="login-btn"
                            onClick={ () => setSecondScreenVisible(true) }
                            type="button"
                          >
                            { "Next" }
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* /Login Content */ }
                </div>
              </div>
              <div className="row mt-1 socials">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  {/* <LoginSocialGoogle
                    client_id={ process.env.REACT_APP_GG_APP_ID || "" }
                    scope={ "email" }
                    // onLoginStart={onLoginStart}
                    onResolve={ ({ provider, data }) => {
                      setProvider(provider);
                      setGoogleBody(data);
                      setSecondScreenVisibleSocial(true);
                      // regSocialUser(data, provider);
                    } }
                    onReject={ (err) => {
                      console.log(err);
                    } }
                  > */}
                  <button className="social-login-google" type="button">
                    <i className="fa fa-google m-2"></i>
                    { isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      t("Signup With Google")
                    ) }
                  </button>
                  {/* </LoginSocialGoogle> */ }
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <LoginSocialFacebook
                    appId={ process.env.REACT_APP_FB_APP_ID || "" }
                    onResolve={ ({ provider, data }) => {
                      setProvider(provider);
                      setGoogleBody(data);
                      regSocialUser(data, provider);
                    } }
                    onReject={ (err) => {
                      console.log(err);
                    } }
                  >
                    <button className="social-login-facebook" type="button">
                      <i className="fa fa-facebook m-2"></i>
                      { isLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        t("Signup With Facebook")
                      ) }
                    </button>
                  </LoginSocialFacebook>
                </div>
                <div className="col-6 dont-have">
                  { t("Already on Wizafri?") }{ " " }
                  <Link to="/login">{ t("Click here") }</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
