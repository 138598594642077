import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import { Button, Form, Spinner } from "react-bootstrap";
import FloatingInputField from "../FloatingInputField";
import { useForm } from "react-hook-form";
import { submitProposal } from "../../services/api/seller";
import { rejectProjectReq } from "../../services/api/index";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
const BuyerRequests = (props) => {
  const buyerRequests = props?.data;
  const state = useSelector((state) => state);
  const { id, role } = state.auth.user;
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [proposalId, setProposalId] = useState(null);
  const [rejectProposalAlert, setRejectPropsalAlert] = useState(false);
  const [afterConfirm, setafterConfirm] = useState(false);

  const [postId, setPostId] = useState(null);
  const navigate = useNavigate();
  const toggleModal = (postId) => {
    setPostId(postId);
    setModalVisible(!modalVisible);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { biddingAmount: "", notes: "" } });

  const handleSubmitProposal = async (body) => {
    setIsLoading(true);
    try {
      await submitProposal(id, postId, body);
      setModalVisible(!modalVisible);
      navigate(`/seller/dashboard`);
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
    setIsLoading(false);
  };

  const rejectProposal = (proposalId) => {
    setProposalId(proposalId);
    setRejectPropsalAlert(true);
  };

  const onConfirmRejectProposal = async () => {
    setIsLoading(true);
    try {
      setRejectPropsalAlert(false);
      setafterConfirm(true);
      await rejectProjectReq(id, proposalId);
      navigate(0);
    } catch (err) {
      setErrorMessage(err.response || err.response.data.message);
    }
    setIsLoading(false);
  };

  const cancelRejectProsal = (proposalId) => {
    setProposalId(null);
    setRejectPropsalAlert(false);
  };

  return (
    <div className="mb-4">
      {rejectProposalAlert && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          customIcon="https://img.icons8.com/?size=512&id=fYgQxDaH069W&format=png"
          title="Cancel Proposal ?"
          onConfirm={onConfirmRejectProposal}
          onCancel={cancelRejectProsal}
        >
          Are You sure to Cancel this project
        </SweetAlert>
      )}
      <div className="row">
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 flex-wrap">
          <div className="table-responsive table-box">
            <table className="table table-center table-hover datatable">
              <thead className="thead-pink">
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Budget Amount</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {buyerRequests.map((buyerRequest, index) => (
                  <tr key={index + 1}>
                    <td>{buyerRequest.title}</td>
                    <td
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: buyerRequest.description,
                      }}
                    ></td>
                    <td>${buyerRequest.offer_amount}</td>
                    <td>
                      {new Date(buyerRequest.createdAt).toLocaleDateString()}
                    </td>

                    <td>
                      {role === "Buyer" ? (
                        <>
                          <div
                            style={{
                              display: "inline-grid",
                            }}
                          >
                            <Link
                              to={`/buyer/buyerRequests/${buyerRequest.id}/proposals`}
                              className="projects-btn"
                            >
                              View Proposals{" "}
                            </Link>
                            {buyerRequest.status !== "in-active" && (
                              <Button
                                className="btn btn-sm btn-warning"
                                onClick={() => rejectProposal(buyerRequest.id)}
                              >
                                <i className="fas fa-close" />
                                Cancel
                              </Button>
                            )}
                          </div>
                        </>
                      ) : (
                        <Button onClick={() => toggleModal(buyerRequest.id)}>
                          Submit Proposal
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <CustomModal title={"Success"} hide={toggleModal} show={modalVisible}>
            <div className="modal-header">
              <h4 className="modal-title">PROPOSAL</h4>
              <span className="modal-close">
                <a
                  onClick={() => setModalVisible(!modalVisible)}
                  aria-label="Close"
                >
                  <i className="fa fa-times-circle" />
                </a>
              </span>
            </div>
            <Form>
              <FloatingInputField
                name={"biddingAmount"}
                type={"number"}
                placeHolder={"Bidding Amount"}
                label={"Bidding Amount"}
                control={control}
                rules={{ required: true }}
                errors={errors}
                errorMessage={"Bidding Amount is required"}
              />
              <FloatingInputField
                name={"notes"}
                type={"textArea"}
                placeHolder={"Notes"}
                label={"Notes"}
                control={control}
                rules={{ required: false }}
                errors={errors}
              />
              <Button
                onClick={handleSubmit(handleSubmitProposal)}
                type="submit"
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit Propsal"
                )}
              </Button>
            </Form>
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default BuyerRequests;
